import clsx from 'clsx';
import { isArray, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { TileType } from 'consts';
import { usePosts } from 'contexts';
import { getQueryParam } from 'utils';

import MultiPostSection from './MultiPostSection';
import SinglePostSection from './SinglePostSection';

import './MainPage.scss';

const postTileTypeMap = {
  0: TileType.WIDE,
  5: TileType.BANNER,
};

const postTileTypeMapEn = {
  0: TileType.WIDE,
};

const SECTION_SIZE = 4;

/**
 * This methods will group the posts into sections.
 *
 * A section can contain single (indices defined by SINGLE_ARTICLE_INDICES) or
 * or multiple (the size of the section is defined by SECTION_SIZE) posts.
 *
 * @param posts The posts which needs to be grouped to sections.
 * @returns Array result will contain an array. If an array item is a single object, that is a single post which nee
 */
const groupPosts = (posts) => {
  const result = [];
  let group = [];
  posts.forEach((post, idx) => {
    const postTileVal = post.segment === 'EN' ? postTileTypeMapEn[idx] : postTileTypeMap[idx];
    const tileType = postTileVal || TileType.SQUARE;
    if ([TileType.BANNER, TileType.WIDE].includes(tileType)) {
      result.push({ ...post, tileType });
      return;
    }

    group.push({ ...post, tileType });
    if (group.length === SECTION_SIZE) {
      result.push(group);
      group = [];
    }
  });

  if (group.length) {
    result.push(group);
  }

  return result;
};

const MainPage = ({ className/* , ...props */ }) => {
  const { search } = useLocation();

  const { posts/* , categoryPosts */ } = usePosts(null, { previewAttr: getQueryParam(search, 'preview'), slug: getQueryParam(search, 'slug') });

  const groupedPosts = useMemo(() => groupPosts(posts || []), [posts]);

  return (
    <div className={clsx(className, 'main-page')}>
      {groupedPosts.map((postOrPosts, idx) => {
        if (!isArray(postOrPosts)) {
          return <SinglePostSection tileType={idx === 2 ? TileType.BANNER : TileType.WIDE} key={postOrPosts.id} post={postOrPosts} />;
        }
        return <MultiPostSection key={map(postOrPosts, ({ id }) => id).join(',')} posts={postOrPosts} />;
      })}
    </div>
  );
};

MainPage.propTypes = {
  className: PropTypes.string,
};

MainPage.defaultProps = {
  className: '',
};

export default MainPage;
