import {
  each, isArray, map, mapKeys, mapValues,
} from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { Repeat } from 'consts';
import { fromJson } from 'utils';

import { useBanners } from './Services';

const calcRepeatTimeFns = {
  [Repeat.NEVER]: () => new Date(Date.now() + 1),
  [Repeat.ALWAYS]: (date) => date,
  [Repeat.DAILY]: (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1),
  [Repeat.WEEKLY]: (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7),
  [Repeat.MONTHLY]: (date) => new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()),
  [Repeat.CUSTOM]: (date, repeat) => new Date(+date + repeat * 24 * 60 * 1000),
};

const getBannersDisplayTimes = () => fromJson(window?.localStorage?.getItem('bannersDisplayTime')) || {};

const updateBannersDisplayTimes = (ids) => {
  const now = Date.now();
  const bannerDisplayTimes = getBannersDisplayTimes();
  window.localStorage.setItem('bannersDisplayTime', JSON.stringify({
    ...bannerDisplayTimes,
    ...mapValues(mapKeys(isArray(ids) ? ids : [ids], (id) => id), () => now),
  }));
};

const sanitizeAndRandomizeByKey = (items, key = 'priority') => {
  const groups = {};
  each(items, (item) => {
    const itemKey = item[key];
    const group = groups[itemKey] || [];
    group.push(item);
    groups[itemKey] = group;
  });
  return map(groups, (group) => group[Math.floor(Math.random() * group.length)]);
};

const useBannersDisplay = (config = {}) => {
  const { banners } = useBanners();
  const [bannersToDisplay, setBannersToDisplay] = useState();
  const configRef = useRef(config);

  useEffect(() => {
    if (!banners) {
      return;
    }
    const { positionId, limit = undefined, autoDisplay = true } = configRef.current;

    const bannerDisplayTimes = getBannersDisplayTimes();

    const now = Date.now();
    const availableBanners = sanitizeAndRandomizeByKey(
      banners.filter((banner) => {
        if (banner.positionId !== positionId) {
          return false;
        }

        const { id, repeat } = banner;
        const displayTime = bannerDisplayTimes[id];
        const calcExpirationFn = calcRepeatTimeFns[repeat] || calcRepeatTimeFns[Repeat.CUSTOM];
        return !displayTime || (calcExpirationFn && now >= calcExpirationFn(new Date(displayTime), repeat));
      })
        .sort(({ priorityA }, { priorityB }) => priorityA - priorityB)
        .slice(0, limit),
    );

    if (autoDisplay) {
      updateBannersDisplayTimes(availableBanners.map(({ id }) => id));
    }

    setBannersToDisplay(availableBanners);
  }, [banners]);

  return {
    banners: bannersToDisplay,
    updateBannersDisplayTimes,
  };
};

export default useBannersDisplay;
