/* eslint-disable react/button-has-type */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './Button.scss';

const IconButton = ({
  className, text, variant, outlined, icon, alt, children, ...buttonProps
}) => (
  <button className={clsx('button', className, variant, { text, outlined })} {...buttonProps}>
    {icon && <img src={icon} alt={alt} />}
    {children}
  </button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  type: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  alt: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
  outlined: PropTypes.bool,
};

IconButton.defaultProps = {
  className: '',
  style: {},
  type: 'button',
  icon: undefined,
  alt: undefined,
  onClick: undefined,
  children: '',
  text: false,
  variant: 'dark',
  outlined: false,
};

export default IconButton;
