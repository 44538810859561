import React from 'react';
import { Container } from 'react-bootstrap';

import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';

import './Footer.scss';

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <MobileFooter />
      <DesktopFooter />
    </Container>
  </footer>
);

export default Footer;
