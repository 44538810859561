import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { PostPropType } from 'consts';
import { PostTile } from 'view/components';

import './MultiPostSection.scss';

const MultiPostSection = ({ className, posts, ...props }) => (
  <section className={clsx('multi-post-section', className)} {...props}>
    <Row className="px-4 px-sm-3 px-lg-5">
      {posts.map((post) => (
        <Col key={post.id} xs={12} sm={6} xxl={3} className="pb-4 px-lg-4">
          <PostTile className={`post-${post.id}`} {...post} />
        </Col>
      ))}
    </Row>
  </section>
);

MultiPostSection.propTypes = {
  className: PropTypes.string,
  posts: PropTypes.arrayOf(PostPropType).isRequired,
};

MultiPostSection.defaultProps = {
  className: '',
};
export default MultiPostSection;
