import {
  isPlainObject, map, trim, trimEnd,
} from 'lodash';

export const calcQueryParams = (params) => {
  const paramsList = map(params, (value, name) => (
    value ? `${name}=${isPlainObject(value) && value.raw ? value.raw : encodeURIComponent(value)}` : undefined
  )).filter((param) => !!param);

  return paramsList.length ? `?${paramsList.join('&')}` : '';
};

export const joinPath = (...args) => args
  .map((uri, idx) => (idx ? trim(uri, '/') : trimEnd(uri, '/')))
  .join('/');
