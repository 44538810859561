import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { TileType } from 'consts';
import { usePostsByTagSlug, useScrollLoader, useTag } from 'contexts';
import { PostRedirect } from 'view/base';
import { PostTile } from 'view/components';

import './TagPage.scss';

const TagPage = ({ className, tagSlug }) => {
  const { tag } = useTag(tagSlug);
  const { posts, loadMore } = usePostsByTagSlug(tagSlug);
  useScrollLoader(loadMore);

  if (tag === null) {
    return <Redirect to="/" />;
  }

  if (posts && posts.length === 1) {
    return <PostRedirect post={posts[0]} />;
  }

  return (
    <div className={clsx(className, 'tag-page')}>
      {tag && posts && (
        <div className="posts">
          <div className="tag-title">{tag.name}</div>
          <Row>
            {(posts || []).map((post) => (
              <Col key={post.id} xs={12} sm={3}>
                <PostTile {...post} tileType={TileType.SQUARE} />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

TagPage.propTypes = {
  className: PropTypes.string,
  tagSlug: PropTypes.string.isRequired,
};

TagPage.defaultProps = {
  className: '',
};

TagPage.Routed = ({ match, ...props }) => <TagPage tagSlug={match.params.tag} {...props} />;
TagPage.Routed.propTypes = {
  ...ReactRouterPropTypes,
};

export default TagPage;
