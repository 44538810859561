import React, { useCallback, useMemo, useState } from 'react';

import { usePostVote } from 'contexts';

const VoteStatus = {
  SUCCESS: 'SUCCESS',
  SUCCESS_ALREADY_VOTED: 'SUCCESS_ALREADY_VOTED',
  ERROR_POLL_NOT_YET_OPENED: 'ERROR_POLL_NOT_YET_OPENED',
  ERROR_POLL_CLOSED: 'ERROR_POLL_CLOSED',
  ERROR_LOGIN_FACEBOOK: 'ERROR_LOGIN_FACEBOOK',
  ERROR_TOKEN_VALIDATION: 'ERROR_TOKEN_VALIDATION',
  ERROR_SERVICE_ERROR: 'ERROR_SERVICE_ERROR',
};

const VoteStatusCode = {
  200: VoteStatus.SUCCESS,
  201: VoteStatus.SUCCESS_ALREADY_VOTED,
  403: VoteStatus.ERROR_POLL_NOT_YET_OPENED,
  401: VoteStatus.ERROR_TOKEN_VALIDATION,
  410: VoteStatus.ERROR_POLL_CLOSED,
};

const VoteStatusMessage = {
  [VoteStatus.SUCCESS]: {
    icon: <span className="green">check_circle_outline</span>,
    title: 'Gratulálok!',
    content: 'Gratulálunk! Sikeresen szavazott a történetre.',
    button: 'Rendben',
  },
  [VoteStatus.SUCCESS_ALREADY_VOTED]: {
    icon: <span className="green">sentiment_satisfied_alt</span>,
    title: 'Megismételt szavazás!',
    content: 'Ezzel a fiókkal már egyszer szavazott erre a történetre.',
    button: 'Rendben',
  },
  [VoteStatus.ERROR_POLL_NOT_YET_OPENED]: {
    icon: <span className="red">access_time</span>,
    title: 'Sikertelen szavazás!',
    content: 'Nem sikerült szavazni, mert a szavazás még nem kezdődött el!',
    button: 'Rendben',
  },
  [VoteStatus.ERROR_POLL_CLOSED]: {
    icon: <span className="red">lock</span>,
    title: 'Siketrelen szavazás!',
    content: 'Nem sikerült szavazni, mert a szavazás már lezárult!',
    button: 'Rendben',
  },
  [VoteStatus.ERROR_LOGIN_FACEBOOK]: {
    icon: <span className="red">error_outline</span>,
    title: 'Facebook bejelentkezési hiba!',
    content: 'A hitelestésbe hiba csúszott! Szavazni csak hitelesített módon, Facebook fiókkal lehet!',
    button: 'Bezárás',
  },
  [VoteStatus.ERROR_TOKEN_VALIDATION]: {
    icon: <span className="red">error_outline</span>,
    title: 'Érvénytelen Facebbok azonosító!',
    content: 'A szavazatodat nem tudtuk befogadni, mert az azonosságot nem lehetett hitelesíteni!',
    button: 'Bezárás',
  },
  [VoteStatus.ERROR_SERVICE_ERROR]: {
    icon: <span className="red">error_outline</span>,
    title: 'Technikai probléma!',
    content: (
      <div>
        Sajnos a szavazás közben nem várt, technikai probléma lépett fel!<br />
        Kérlek ismételd meg később, vagy jelezd a honlap üzemeltetőjének!
      </div>
    ),
    button: 'Bezárás',
  },
};

const usePoll = (poll, refetch) => {
  const [state, setState] = useState({
    status: undefined,
  });

  const { startDate, endDate } = poll || {};
  const available = useMemo(
    () => {
      const now = Date.now();
      return poll && (!startDate || +new Date(startDate) < now) && (!endDate || +new Date(endDate) > now);
    },
    [poll, startDate, endDate],
  );

  const { postVote } = usePostVote();

  const onLoginComplete = useCallback(({ profile, tokenDetail, eventKey: postId } = {}) => {
    postVote({ postId, tokenDetail, profile })
      .then(({ response, error }) => {
        if (error) {
          const status = VoteStatusCode[response && response.status] || VoteStatus.ERROR_SERVICE_ERROR;
          setState((prevState) => ({ ...prevState, status }));

          return;
        }

        const status = VoteStatusCode[response.status];
        setState((prevState) => ({ ...prevState, status: status || VoteStatus.ERROR_SERVICE_ERROR }));

        if (refetch) {
          refetch();
        }
      });
  }, [postVote, refetch]);

  const onLoginError = useCallback((/* error */) => {
    setState((prevState) => ({ ...prevState, status: VoteStatus.ERROR_LOGIN_FACEBOOK }));
  }, []);

  const reset = useCallback(() => {
    setState({ status: undefined });
  }, []);

  return {
    ...state,
    message: VoteStatusMessage[state.status],
    available,
    onLoginComplete,
    onLoginError,
    reset,
  };
};

export default usePoll;
