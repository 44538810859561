const recommendedNews = [{
/*
  id: 1,
  title: 'Kormányzati támogatással éledhetnek újjá a falvak',
  lead: 'Bölcsődék, óvodák és háziorvosi szolgálati házak épülnek vidéken',
  writer: 'Borsodi Attila',
  url: 'https://magyarnemzet.hu/belfold/feltamasztanak-a-falvakat-6696845/',
  source: 'Magyar Nemzet',
  imageUrl: '/resources/images/news-magyarnemzet-logo.png',
}, {
  id: 2,
  title: 'Nekem a Balaton…',
  lead: '8+1 felfedezni való a tavaszi szünetre',
  writer: 'Koltay Anna',
  url: 'https://kepmas.hu/nekem-a-balaton-81-felfedezni-valo-a-tavaszi-szunetre',
  source: 'Képmás',
  imageUrl: '/resources/images/news-kepmas-logo-300.png',
}, {
  id: 3,
  title: '„Aggódva figyelem a világ változását”',
  lead: `Szalóki Ági énekes, dalszerző gyerekzenekara vezetőjeként 15 éve foglalkozik gyerekek zenei nevelésével.
        Művészetével igyekszik a hagyományos értékek és a természet tiszteletének fontosságára felhívni a figyelmet.
        Ágit május 11-én a Margitszigeten egy ingyenes gyerekkoncerten hallhatják az érdeklődők, ahol egy világrekord
        kísérletben is részt vehetnek a családok.`,
  writer: 'Három Királyfi Három Királylány Mozgalom',
  url: 'https://kepmas.hu/szaloki-agi-aggodva-figyelem-a-vilag-valtozasat',
  source: 'Képmás',
  imageUrl: '/resources/images/news-kepmas-logo-300.png',
}, {
  id: 4,
  title: 'Magyaroknak örök például',
  lead: 'Gyermeknapon, május 26-án mutatják be a Gárdonyi Géza regénye nyomán készült Egri csillagok című musical felújított változatát a Budapest Arénában.',
  writer: 'Csejk Miklós',
  url: 'https://magyarnemzet.hu/kultura/magyaroknak-orok-peldaul-6822329/',
  source: 'Magyar Nemzet',
  imageUrl: '/resources/images/news-magyarnemzet-logo.png',
}, {
*/
  id: 5,
  title: '3 gyerek, 4 kerék, 7 ülés: végre együtt utazhat a család!',
  lead: 'Pauler Attila és családja egy fővárosi autószalonban választották ki az új autójukat a napokban. Nem terveztek új gépjárművet beszerezni, csak azt követően döntöttek a vásárlás mellett, hogy a kormány bejelentette: a nagycsaládosok 2,5 millió forint állami támogatást kapnak, ha egy új, legalább 7 személyes autót vásárolnak.',
  writer: 'csalad.hu',
  url: '/cikk/3-gyerek-4-kerek-7-ules-vegre-egyutt-utazhat-a-csalad',
  source: '',
  imageUrl: '/resources/images/csalad-hu-logo.png',
}, {
  id: 7,
  title: 'Babaváró Támogatás',
  lead: 'Kíváncsi rá, hogy ki, mikor és hogyan kaphat segítséget, ha babát tervez? Íme a legfontosabb információk.',
  writer: 'csalad.hu',
  url: '/cikk/babavaro-tamogatas',
  source: '',
  imageUrl: '/resources/images/csalad-hu-logo.png',
}, {
  id: 6,
  title: 'Mindent a CSOK-ról',
  lead: 'A CSOK vissza nem térítendő állami támogatás, amely az otthonteremtést segíti.',
  writer: 'csalad.hu',
  url: '/cikk/csok',
  source: '',
  imageUrl: '/resources/images/csalad-hu-logo.png',
}];

export default recommendedNews;
