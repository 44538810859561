import { find, map, merge } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { FacebookProvider } from 'react-facebook';
import { Helmet } from 'react-helmet-async';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';

import {
  Segment, ThematicPages,
  getCategoryRoute, getPostRoute, getSearchRoute, getSearchUrl, getSegmentUrl, getTagRoute,
  FACEBOOK_APP_ID, FACEBOOK_DOMAIN_VERIFICATION,
} from 'consts';
import { ToggledCategoryProvider, usePosts, useRedirects } from 'contexts';
import { getQueryParam } from 'utils';
import { CookiePopup } from 'view/components';
import { Footer, Header, Newsletter } from 'view/modules';
import {
  CategoryPage, MainPage, PostPage, SearchPage, TagPage, VedjegyKereso,
} from 'view/pages';

import Popup from './Popup';
import './App.scss';
import DigitalSolutions from './components/DigitalSolutions';

/*
const getStoredA11y = () => {
  try {
    return window && window.localStorage && JSON.parse(window.localStorage.getItem('a11y'));
  } catch (ex) {
    return false;
  }
};
*/

const staticRedirects = [{
  source: new RegExp('^/csaladvedelmi-akcioterv/hirek$'),
  target: '/csaladvedelmi-akcioterv',
}, {
  source: new RegExp('^/csaladvedelmi-akcioterv/gyik$'),
  target: '/tamogatas/csaladvedelmi-akcioterv-gyik',
}, {
  source: new RegExp('^/otthonteremtesi-program/hirek$'),
  target: '/otthonteremtesi-program',
}, {
  source: new RegExp('^/otthonteremtesi-program/gyik$'),
  target: '/tamogatas/otthonteremtesi-program-gyik',
}, {
  source: new RegExp('^/palyaorientacio'),
  target: '/kategoria/palyaorientacio',
}, {
  source: new RegExp('^/gyermekut'),
  target: 'https://gyermekut.hu/',
}, {
  source: new RegExp('^/emmike'),
  target: '/csaladban-elni/a-munkahelyi-bolcsode-elonyos-a-munkaadoknak-segitseg-a-munkavallaloknak-es-csaladjaiknak',
}, {
  source: new RegExp('^/okit'),
  target: 'https://okit.hu/',
}, {
  source: new RegExp('^/kepzes'),
  target: '/kategoria/aktualis-kepzesek-workshopok',
}];

const calcRedirectUrl = (pathname, dbSources) => {
  let result = find(staticRedirects, ({ source }) => pathname.match(source));
  if (result?.target) {
    return result.target;
  }

  result = find(dbSources, ({ source }) => {
    const srcMatch = new RegExp(source);
    return pathname.match(srcMatch);
  });

  // TODO: replace `target` variables
  return result?.target || null;
};

const mapThematicComponent = (componentProps) => ({
  PostPage: (props) => <PostPage {...merge({}, props, componentProps)} />,
  CategoryPage: (props) => <CategoryPage.Routed {...merge({}, props, componentProps)} />,
  TagPage: (props) => <TagPage.Routed {...merge({}, props, componentProps)} />,
});

const renderThematicPage = ({ path, component, props }, thematicPageKey) => (
  <Route key={thematicPageKey} path={path} component={mapThematicComponent(props)[component]} />
);

const App = () => {
  usePosts({ autoStart: false, context: 'App' });
  const { pathname, search } = useLocation();

  useEffect(() => {
    const { ga, fbq } = window;
    if (typeof ga === 'function') {
      ga('set', 'page', pathname + search);
      ga('send', 'pageview');
    }

    fbq('track', 'ViewContent');
  }, [pathname, search]);

  const { redirects } = useRedirects();

  const onLogoClick = useCallback((event) => {
    event.preventDefault();
    window.location.href = '/';
  }, []);

  // search via goolge search
  if (pathname === '/') {
    const searchQuery = getQueryParam(search, 's');
    if (searchQuery) {
      return <Redirect to={getSearchUrl({ s: searchQuery })} />;
    }
  }

  const redirectUrl = calcRedirectUrl(pathname, redirects?.sort((a, b) => b?.source?.length - a?.source?.length));
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <FacebookProvider appId={FACEBOOK_APP_ID}>
      <Helmet>
        <html lang="hu" prefix="og: http://ogp.me/ns/website# fb: http://ogp.me/ns/fb#" />
        <title>csalad.hu - Ahol otthon vagy!</title>
        {FACEBOOK_DOMAIN_VERIFICATION && <meta name="facebook-domain-verification" content={FACEBOOK_DOMAIN_VERIFICATION} />}
      </Helmet>
      <ToggledCategoryProvider>
        <div className="app">
          <Header onLogoClick={onLogoClick} />
          <Switch>
            <Route path="/" exact component={MainPage} />
            <Route path="/csaladbarat-vedjegy-kereso" exact component={VedjegyKereso} />
            {map(Segment, (segment) => <Route key={segment} path={getSegmentUrl(segment)} exact component={MainPage} />)}
            {map(Segment, (segment) => (<Route key={segment} path={getPostRoute(segment)} exact component={PostPage} />))}
            {map(Segment, (segment) => <Route key={segment} path={getCategoryRoute(segment)} exact component={CategoryPage.Routed} />)}
            {map(Segment, (segment) => <Route key={segment} path={getTagRoute(segment)} exact component={TagPage.Routed} />)}
            <Route path={getSearchRoute()} exact component={SearchPage} />
            {map(ThematicPages, renderThematicPage)}
            {redirects ? <Redirect to="/" /> : null}
          </Switch>
          <Switch>
            <Route path="/kategoria/women-families-careers-digital-solutions-in-adult-learning" component={DigitalSolutions} exact />
            <Route path="/kategoria/nok-csalad-karrier-digitalis-megoldasok-a-felnott-tanulasban" component={DigitalSolutions} exact />
            <Route path="/" component={Newsletter} />
          </Switch>
          <Footer />
        </div>
      </ToggledCategoryProvider>
      <Popup />
      <CookiePopup />
    </FacebookProvider>
  );
};

export default App;
