import {
  Segment, ImageCrop, POST_MAX_PRIORITY, getCommonConfig,
} from '@csalad-2021/common';

export * from './prop-types';
export * from './path';
export * from '@csalad-2021/common';

export { default as recommendedNews } from './recommended-news';
export { default as servicesConfig } from './services';

export const LEAD_MAX_LEN = 220;

const { HOST_URL, FACEBOOK_APP_ID, FACEBOOK_DOMAIN_VERIFICATION } = getCommonConfig(process.env);
export { HOST_URL, FACEBOOK_APP_ID, FACEBOOK_DOMAIN_VERIFICATION };

export const ImageSize = {
  BANNER: 280,
  POPUP: 580,
  FACEBOOK: 1200,
  TWITTER: 280,
  NORMAL: 1920,
  SMALL: 400,
};

export const NoImageFile = {
  [ImageCrop.WIDE]: 'no-image-wide.png',
  [ImageCrop.NARROW]: 'no-image-narrow.png',
  [ImageCrop.SQUARE]: 'no-image-square.png',
};

export const BASE_URL = '/api';

export const IS_BROWSER = typeof window !== 'undefined';
export const IS_SSR = !IS_BROWSER;
if (IS_SSR) {
  global.window = global;
}

export const SegmentText = {
  [Segment.FAMILY]: 'Családban élni',
  [Segment.EN]: 'EN',
  [Segment.SUBSIDY]: 'Támogatások',
  [Segment.PROJECT]: 'Kampány',
};

export const BannerPosition = {
  POPUP_AT_LOAD: 'POPUP_AT_LOAD',
};

export const SegmentsList = [Segment.FAMILY, Segment.SUBSIDY, Segment.PROJECT, Segment.EN].map((segment) => ({ segment, text: SegmentText[segment] }));

export const PaginationConfig = {
  MainPostFirst: POST_MAX_PRIORITY,
  MainPostNext: 16,
  CategoryPostFirst: 24,
  CategoryPostNext: 24,
  TagPostFirst: 16,
  TagPostNext: 16,
  SearchPostFirst: 24,
  SearchPostNext: 24,
};

export const TileType = {
  WIDE: 'WIDE',
  BANNER: 'BANNER',
  SQUARE: 'SQUARE',
};
