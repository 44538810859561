import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';

import { ImageSize } from 'consts';
import { getImageUrl } from 'utils';
import { Button, Link } from 'view/base';

import { usePopup, useTransition } from '../contexts';

import './Popup.scss';

const Popup = () => {
  const { popup, showNextPopup } = usePopup();
  const { enter, leave, ...transition } = useTransition();
  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.classList[popup ? 'add' : 'remove']('no-scroll');
  }, [popup]);

  const onCloseClick = useCallback(async () => {
    await leave();
    showNextPopup();
  }, [leave, showNextPopup]);

  const onImageLoad = useCallback(() => {
    enter();
  }, [enter]);

  if (!popup) {
    return '';
  }

  return (
    <div className="popup-mask">
      <div className={clsx('popup', transition)}>
        <Link to={popup.target} title={popup.name} onClick={onCloseClick}>
          <img src={getImageUrl(popup.imageMediaId, ImageSize.POPUP, false)} alt={popup.name} title={popup.name} onLoad={onImageLoad} />
        </Link>
        <Button className="toggle-button material-icons" onClick={onCloseClick} title="Ablak bezárása">close</Button>
      </div>
    </div>
  );
};

export default Popup;
