import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { recommendedNews } from 'consts';

import './RecommendedNews.scss';

const useRecommendedNews = () => ({ recommendedNews });

const RecommendedNews = ({ className }) => {
  const { recommendedNews: news } = useRecommendedNews();

  if (!news || !news.length) {
    return '';
  }

  return (
    <div className={clsx('recommended-news', className)}>
      <div className="head">Hírajánló</div>
      {news.map(({
        id, title, lead, writer, source, url = '', imageUrl, openWindow,
      }) => {
        const http = url.startsWith('http://') || url.startsWith('https://');
        const blank = openWindow === true || (openWindow !== false && http);
        return (
          <a key={id} className="news" href={url} target={blank ? '_blank' : ''} rel="noopener noreferrer">
            <div className="writer-source-wrapper">
              <img className="source-image" src={imageUrl} alt={title} title={title} />
              <div className="writer-source">
                <div className="writer">{writer}</div>
                <div className="source">{source}</div>
              </div>
            </div>
            {title && <div className="title">{title}</div>}
            {lead && <div className="lead">{lead}</div>}
          </a>
        );
      })}
    </div>
  );
};

RecommendedNews.propTypes = {
  className: PropTypes.string,
};

RecommendedNews.defaultProps = {
  className: '',
};

export default RecommendedNews;
