import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { getCategoryUrl } from 'consts';
import { useSegment } from 'contexts';
import { findCategoryById } from 'utils';

import './CategoryBreadcrumb.scss';

const CategoryBreadcrumb = ({ className, categories, category }) => {
  const { activeSegment } = useSegment();

  if (!category || !categories) {
    return '';
  }

  const breadcrumbCategories = [];
  for (let categoryIter = category; categoryIter; categoryIter = findCategoryById(categories, categoryIter.parentId)) {
    breadcrumbCategories.unshift(categoryIter);
  }

  return (
    <div className={clsx('category-breadcrumb', className)}>
      {breadcrumbCategories.map(({ id, name, slug }) => <NavLink key={id} to={getCategoryUrl({ slug, segment: activeSegment })} className="bread">{name}</NavLink>)}
    </div>
  );
};

CategoryBreadcrumb.propTypes = {
  className: PropTypes.string,
  category: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
};

CategoryBreadcrumb.defaultProps = {
  className: '',
  category: undefined,
  categories: undefined,
};

export default CategoryBreadcrumb;
