import clsx from 'clsx';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { PostPropType, TileType } from 'consts';
import { PostTile } from 'view/components';

const SinglePostSection = ({
  className, post, tileType, ...props
}) => (
  <section className={clsx('single-post-section', 'pb-4', 'pb-sm-3', 'pb-lg-5', className)} {...props}>
    {tileType === TileType.BANNER ? (
      <Row className="pb-4 px-4 px-sm-3 px-lg-5">
        <Col key={post.id} xs={12} className="px-lg-4">
          <PostTile key={post.id} tileType={tileType} {...post} />
        </Col>
      </Row>
    ) : (
      <PostTile key={post.id} tileType={tileType} {...post} />
    )}
  </section>
);

SinglePostSection.propTypes = {
  className: PropTypes.string,
  post: PostPropType.isRequired,
  tileType: PropTypes.oneOf(map(TileType)),
};

SinglePostSection.defaultProps = {
  className: '',
  tileType: TileType.WIDE,
};

export default SinglePostSection;
