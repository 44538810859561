import PropTypes from 'prop-types';
import React from 'react';

import { calcCategoryColor, findCategoryById } from 'utils';

import CategoryLink from './CategoryLink';

const CategoryMenuList = ({
  categories, allCategories, rootUrl, prefixUrl,
}) => categories
  .map(({
    id,
  }) => {
    const category = findCategoryById(allCategories, id);
    const categoryColor = category && categories && calcCategoryColor(category, categories);

    return (
      <li key={id} style={{ color: categoryColor }}>
        <CategoryLink className="menu-small-text" category={category} rootUrl={rootUrl} prefixUrl={prefixUrl} />
      </li>
    );
  });

CategoryMenuList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CategoryMenuList;
