import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './Checkbox.scss';

const Checkbox = ({
  id, className, checked, value, onChange, label,
}) => {
  const handleChange = (event) => {
    onChange(event, !checked);
  };

  return (
    <div className={clsx('checkbox', className)}>
      <span className="check-wrap">
        <input type="checkbox" id={id} value={value} checked={checked} onChange={handleChange} />
      </span>
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
};

Checkbox.defaultProps = {
  id: 'checkboxId',
  className: '',
  value: '',
  label: null,
};

export default Checkbox;
