import PropTypes from 'prop-types';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { getCategoryUrl, CategoryPropType } from 'consts';

const CategoryLink = ({
  className, category, rootUrl, prefixUrl, ...props
}) => {
  const { slug, name, attributes } = category;
  const target = attributes?.target;
  if (target) {
    const targetBlank = attributes?.targetBlank;
    if (target.startsWith('http://') || target.startsWith('https://')) {
      return <Nav.Link className={className} href={target} target={targetBlank ? '__blank' : undefined} {...props}>{name}</Nav.Link>;
    }
    return <Nav.Link className={className} as={NavLink} to={target} {...props}>{name}</Nav.Link>;
  }

  const to = getCategoryUrl({ slug, rootUrl, prefixUrl });
  return <Nav.Link className={className} as={NavLink} to={to} {...props}>{name}</Nav.Link>;
};

CategoryLink.propTypes = {
  className: PropTypes.string,
  category: CategoryPropType.isRequired,
  rootUrl: PropTypes.string,
  prefixUrl: PropTypes.string,
};

CategoryLink.defaultProps = {
  className: '',
  rootUrl: '',
  prefixUrl: '',
};

export default CategoryLink;
