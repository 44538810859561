import axios from 'axios';
import {
  isArray, isObject, isPlainObject, isString, merge, reduce, set, mapValues,
} from 'lodash';

const normalizeConfig = (config) => (isString(config) ? {
  method: 'get',
  url: config,
  params: {},
} : {
  ...config,
  method: config.method || 'get',
  params: config.params || {},
});

const createService = (id, serviceConfig) => {
  if (!serviceConfig) {
    throw new Error('Service configuration is missing!');
  }

  const apiFn = (params = {}, configOverride) => {
    const config = merge({}, normalizeConfig(serviceConfig), configOverride || {});

    const processedParams = reduce(config.params, (result, type, name) => {
      const value = params[name];
      if (value === undefined) {
        return result;
      }

      const { queryParams, url, data } = result;
      if (type === 'query') {
        return { ...result, queryParams: set(queryParams, name, value) };
      }

      if (type === 'url') {
        return { ...result, url: url.replace(new RegExp(`:${name}`, 'g'), value || '') };
      }

      if (type === 'body' && isPlainObject(data || {})) {
        return { ...result, data: set(data || {}, name, value) };
      }

      if (type === 'data') {
        if (isArray(value) && isArray(data || [])) {
          return { ...result, data: [...data, ...value] };
        }

        if (isPlainObject(value) && isPlainObject(data || {})) {
          return { ...result, data: { ...data, ...value } };
        }

        if (data === undefined) {
          return { ...result, data: value };
        }
      }

      console.warn(`Warning! Parameter '${name}' with type '${type}' is invalid or does not match with previous params types!`); // eslint-disable-line

      return result;
    }, {
      queryParams: {},
      url: config.url,
      data: undefined,
    });

    const { queryParams, url, data } = processedParams;
    return (config.api || axios)({
      ...config,
      url,
      params: queryParams,
      data: isObject(data) ? JSON.stringify(data) : data,
    });
  };

  apiFn.id = id;
  apiFn.config = serviceConfig;

  return apiFn;
};

const provider = (services, baseConfig) => mapValues(services, (config, name) => {
  const id = (isPlainObject(config) && config.id) || name;
  return createService(id, merge({}, baseConfig, config));
});

export default provider;
