import { useCallback, useEffect, useState } from 'react';

import { BannerPosition } from 'consts';

import useBannersDisplay from './useBannersDisplay';

const usePopup = ({ delay = 1000 } = {}) => {
  const [state, setState] = useState({ popup: null, available: null });
  const { banners: popups, updateBannersDisplayTimes } = useBannersDisplay({
    positionId: BannerPosition.POPUP_AT_LOAD,
    autoDisplay: false,
  });

  const setNextPopup = useCallback(() => {
    setState((prevState) => {
      const availablePopups = prevState.available || popups;
      const nextPopup = availablePopups[0];
      if (nextPopup) {
        updateBannersDisplayTimes(nextPopup.id);
      }

      return {
        ...prevState,
        popup: nextPopup || null,
        available: availablePopups?.slice(1) || null,
      };
    });
  }, [popups, updateBannersDisplayTimes]);

  useEffect(() => {
    if (!popups) {
      return;
    }

    setTimeout(() => setNextPopup(), delay);
  }, [delay, popups, setNextPopup]);

  const showNextPopup = useCallback(() => {
    setNextPopup();
  }, [setNextPopup]);

  return {
    ...state,
    showNextPopup,
  };
};

export default usePopup;
