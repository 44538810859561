import Axios from 'axios';
import { each } from 'lodash';
import LRU from 'lru-cache';
import {
  useCallback, useEffect, useMemo, useReducer, useRef,
} from 'react';

import { IS_BROWSER } from 'consts';
import { getLocation } from 'utils';

export const Actions = {
  START_REQUEST: 'START_REQUEST',
  END_REQUEST: 'END_REQUEST',
};

const createAxiosCache = (options) => {
  const ssrPromises = [];
  const dispatchersList = {};

  const getDispatchers = (cacheKey) => dispatchersList[cacheKey] || [];

  const removeDispatchers = (cacheKey) => {
    dispatchersList[cacheKey] = [];
  };

  const addDispatcher = (cacheKey, dispatch) => {
    const dispatchers = getDispatchers(cacheKey);
    dispatchers.push(dispatch);
    dispatchersList[cacheKey] = dispatchers;
  };

  const removeDispatcher = (cacheKey, dispatch) => {
    dispatchersList[cacheKey] = getDispatchers(cacheKey).filter((item) => item !== dispatch);
  };

  let cache = new LRU();
  let axios = Axios;

  let cacheKeySerializer = ({
    url, method, params, data,
  }) => {
    const { pathname, search } = getLocation(url) || {};
    const serialized = [pathname, search, method.toLowerCase(), params, data].map((value) => JSON.stringify(value)).join('|');
    return serialized;
  };

  const reducer = (state, action) => {
    const {
      type, payload, transformFn,
    } = action;

    if (type === Actions.START_REQUEST) {
      return {
        ...state,
        loading: true,
      };
    }

    if (type === Actions.END_REQUEST) {
      const response = action.error ? payload?.response : payload;
      const error = action.error ? payload : null;
      const { response: res, error: err } = transformFn?.({
        response,
        prevResponse: state.response,
        error,
        prevError: state.error,
      }) || { response, error };

      return {
        ...state,
        loading: false,
        response: res,
        error: err,
      };
    }

    return state;
  };

  const request = async (config, dispatch, state) => {
    const cacheKey = cacheKeySerializer(config);
    const hit = cache.get(cacheKey);
    if (hit) {
      return;
    }

    try {
      dispatch({ type: Actions.START_REQUEST });
      cache.set(cacheKey, reducer(state, { type: Actions.START_REQUEST }));

      const { config: $config, request: $request, ...responseForCache } = await axios(config);
      const action = {
        type: Actions.END_REQUEST,
        payload: responseForCache,
        cacheKey,
      };
      cache.set(cacheKey, reducer(state, action));
      each(getDispatchers(cacheKey), (dispatcher) => dispatcher(action));
      removeDispatchers(cacheKey);
    } catch (error) {
      // in case of request error we delete the cache -- client could try it again
      const {
        config: $errorConfig,
        request: $errorRequest,
        response: { config: $responseConfig, $responseRequest, ...response } = {},
        ...errForCache
      } = error;
      errForCache.response = response;

      if (axios.isCancel(error)) {
        return;
      }

      const action = {
        type: Actions.END_REQUEST,
        payload: errForCache,
        error: true,
        cacheKey,
      };
      cache.set(cacheKey, reducer(state, action));
      each(getDispatchers(cacheKey), (dispatcher) => dispatcher(action));
      removeDispatchers(cacheKey);
    }
  };

  const configure = (opts = {}) => {
    if (opts.axios) {
      ({ axios } = opts);
    }

    if (opts.cache) {
      ({ cache } = opts);
    }

    if (opts.cacheKeySerializer) {
      ({ cacheKeySerializer } = opts);
    }
  };

  const loadCache = (data) => cache.load(data);

  const serializeCache = async () => {
    await Promise.all(ssrPromises);

    ssrPromises.length = 0;

    return cache.dump();
  };

  const useAxiosCache = (cfg) => {
    const configRef = useRef();
    configRef.current = typeof cfg === 'string' ? { method: 'get', url: cfg } : cfg;
    const cacheKey = cacheKeySerializer(configRef.current);
    const initialState = cache.get(cacheKey) || { loading: false };

    const [reducerState, dispatch] = useReducer(reducer, initialState || { loading: false });
    const state = initialState || reducerState;
    const { loading, error, response } = state;
    const ssrState = useMemo(() => {
      if (!IS_BROWSER && !loading && !error && !response && configRef.current.autoStart !== false) {
        const canceller = axios.CancelToken.source();

        const promise = request({
          ...configRef.current,
          cancelToken: canceller.token,
        }, dispatch, state);

        ssrPromises.push(promise);

        return state;
      }
      return state;
    }, [loading, error, response, cacheKey]); // eslint-disable-line

    useEffect(() => {
      if (configRef.current.autoStart === false) {
        return undefined;
      }
      const cachedState = cache.get(cacheKey);
      if (cachedState) {
        if (cachedState.loading) {
          addDispatcher(cacheKey, dispatch);
          return () => {
            removeDispatcher(cacheKey, dispatch);
          };
        }
        return undefined;
      }
      cache.del(cacheKey);

      addDispatcher(cacheKey, dispatch);
      const canceller = axios.CancelToken.source();
      request({
        ...configRef.current,
        cancelToken: canceller.token,
      }, dispatch);

      return () => {
        removeDispatcher(cacheKey, dispatch);
      };
    }, [cacheKey]);

    const refetch = useCallback(() => {
      cache.del(cacheKey);
      const canceller = axios.CancelToken.source();
      request({
        ...configRef.current,
        cancelToken: canceller.token,
      }, dispatch);
    }, [cacheKey]);

    const clearCache = useCallback(() => {
      cache.del(cacheKey);
    }, [cacheKey]);

    const resetCache = useCallback(() => {
      cache.reset();
    }, []);

    const startRequest = useCallback(({ onBeforeStart }) => {
      if (onBeforeStart?.(state) === false) {
        return;
      }

      const action = {
        type: Actions.START_REQUEST, cacheKey, state,
      };
      const newState = reducer(state, { ...action });
      cache.set(cacheKey, newState);
      dispatch(action);
    }, [cacheKey, state]);

    const endRequest = useCallback(({ response: res, error: err, transformFn }) => {
      const action = {
        type: Actions.END_REQUEST,
        payload: err || res,
        error: !!err,
        transformFn,
        cacheKey,
      };
      const newState = reducer(state, action);
      cache.set(cacheKey, newState);
      dispatch(action);
    }, [cacheKey, state]);

    const resultState = IS_BROWSER ? state : ssrState;

    return {
      state: (resultState.response && { ...resultState, data: resultState.response.data }) || resultState,
      refetch,
      clearCache,
      resetCache,
      startRequest,
      endRequest,
    };
  };

  configure(options);

  return {
    configure,
    loadCache,
    serializeCache,
    useAxiosCache,
  };
};

const instance = createAxiosCache();

const {
  configure, loadCache, serializeCache, useAxiosCache,
} = instance;

export {
  configure, loadCache, serializeCache, useAxiosCache, createAxiosCache,
};

export default createAxiosCache;
