/* eslint-disable no-underscore-dangle */
import 'react-app-polyfill/ie11';
import 'core-js/es/string/repeat';
import 'core-js/es/string/starts-with';
import 'core-js/es/number/is-integer';
import 'core-js/es/number/is-nan';

import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { IS_BROWSER } from 'consts';
import { ServiceProvider } from 'contexts';
import { getQueryParam } from 'utils';
import { loadCache } from 'utils//axios-cache';
import App from 'view/App';

import 'normalize.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './index.scss';

if (IS_BROWSER) {
  const preview = !!getQueryParam(window.location.search, 'preview');
  const app = (
    <HelmetProvider>
      <BrowserRouter>
        <ServiceProvider preview={preview}>
          <App />
        </ServiceProvider>
      </BrowserRouter>
    </HelmetProvider>
  );

  if (window.__SSR_CACHE__) {
    loadCache(window.__SSR_CACHE__);
    delete window.__SSR_CACHE__;

    ReactDOM.hydrate(app, document.getElementById('root'));
  } else {
    ReactDOM.render(app, document.getElementById('root'));
  }
}
