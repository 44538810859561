import PropTypes from 'prop-types';
import React from 'react';

import { PostPropType } from 'consts';
import { PostTile } from 'view/components';

import './RelatedPosts.scss';

const RelatedPosts = ({ posts }) => {
  if (!posts.length) {
    return '';
  }

  return (
    <section className="related-posts">
      {
        // .slice(0, 3)
        posts.map((post, idx) => (
          <PostTile
            key={post.id}
            className={`post-${idx}`}
            position={idx}
            {...post}
          />
        ))
      }
    </section>
  );
};

RelatedPosts.propTypes = {
  posts: PropTypes.arrayOf(PostPropType).isRequired,
};

export default RelatedPosts;
