import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { ImageSize, TileType } from 'consts';
import {
  useCategories, usePostsByCategorySlug, useScrollLoader, usePost,
} from 'contexts';
import { calcCategoryColor, findCategoryBySlug, getImageUrl } from 'utils';
import { Link, PostRedirect } from 'view/base';
import { PostTile } from 'view/components';

import './CategoryPage.scss';

const CategoryBanner = ({ data }) => {
  const [imageId, target] = data.split('||||');

  if (!imageId || !target) {
    return null;
  }

  return (
    <Link className="post-tile" to={target}>
      <img style={{ width: '100%' }} src={getImageUrl(parseInt(imageId, 10), ImageSize.NORMAL, false)} alt="banner" />
    </Link>
  );
};

CategoryBanner.propTypes = {
  data: PropTypes.string,
};

const CategoryPage = ({ className, categorySlug }) => {
  const { categories } = useCategories();
  const category = categories && findCategoryBySlug(categories, categorySlug);
  const { post } = usePost(categorySlug, true);
  const { posts, loadMore } = usePostsByCategorySlug(categorySlug);
  useScrollLoader(loadMore);

  if (categories && !category) {
    // ... and return 404 or redirec to root page
    return <Redirect to="/" />;
  }

  if (posts && posts.length === 1) {
    return <PostRedirect post={posts[0]} />;
  }

  const categoryColor = calcCategoryColor(category, categories);
  const { name, imageMediaId, attributes } = category || {};

  let gyujtoOldal = false;
  let orderedPosts = [];
  if (post) {
    gyujtoOldal = !!(post.tags && post.tags.length && post.tags.find((t) => t.name === 'technikai_gyujto_oldal'));
    if (gyujtoOldal && posts && posts.length) {
      orderedPosts = posts.sort((a, b) => {
        if (a.source < b.source) {
          return -1;
        }
        if (a.source > b.source) {
          return 1;
        }
        return 0;
      });
    }
  }
  return (
    <div className={clsx(className, 'category-page')}>
      {category && (
        <>
          {imageMediaId && (
            <img
              className={clsx('category-logo', { 'full-size': attributes?.fullsizeImage })}
              src={getImageUrl({ id: imageMediaId }, ImageSize.NORMAL, false)}
              alt={name}
            />
          )}
          <div className={clsx('category-title', { 'hide-title': attributes?.hideTitle })} style={{ color: categoryColor }}>{name}</div>
          <Switch>
            <Route
              path="/kategoria/women-families-careers-digital-solutions-in-adult-learning"
              component={() => (
                <div style={{ textAlign: 'right', fontSize: '10px', padding: '0 1.5rem' }}>
                  <Link
                    style={{
                      textDecoration: 'none', cursor: 'pointer', textAlign: 'center', padding: '0 1.5rem 1.5rem 1.5rem', display: 'inline-block',
                    }}
                    to="/kategoria/nok-csalad-karrier-digitalis-megoldasok-a-felnott-tanulasban"
                  >
                    <img width="30px" style={{ paddingBottom: '5px' }} src="/resources/images/hun_flag.png" alt="Keresés" title="Keresés" />
                    <br />
                    Magyar verzió
                  </Link>
                </div>
              )}
              exact
            />
            <Route
              path="/kategoria/nok-csalad-karrier-digitalis-megoldasok-a-felnott-tanulasban"
              component={() => (
                <div style={{ textAlign: 'right', fontSize: '10px', padding: '0 1.5rem' }}>
                  <Link
                    style={{
                      textDecoration: 'none', cursor: 'pointer', textAlign: 'center', padding: '0 1.5rem 1.5rem 1.5rem', display: 'inline-block',
                    }}
                    to="/kategoria/women-families-careers-digital-solutions-in-adult-learning"
                  >
                    <img width="30px" style={{ paddingBottom: '5px' }} src="/resources/images/eng_flag.png" alt="Keresés" title="Keresés" />
                    <br />
                    Angol verzió
                  </Link>
                </div>
              )}
              exact
            />
          </Switch>
          <div className="posts">
            <Row>
              {(!gyujtoOldal ? posts : orderedPosts || []).map((postElement, postKey) => (
                <React.Fragment key={postElement.id}>
                  <Col xs={12} sm={3}>
                    <PostTile {...postElement} tileType={TileType.SQUARE} />
                  </Col>
                  {(postKey === 3 && gyujtoOldal && parseInt(post.content, 10) > 0) ? (
                    <Col xs={12} sm={12}>
                      <CategoryBanner data={post.content} />
                    </Col>
                  ) : null}
                </React.Fragment>
              ))}
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

CategoryPage.propTypes = {
  className: PropTypes.string,
  categorySlug: PropTypes.string.isRequired,
};

CategoryPage.defaultProps = {
  className: '',
};

CategoryPage.Routed = ({ match, ...props }) => <CategoryPage categorySlug={match.params.category} {...props} />;
CategoryPage.Routed.propTypes = {
  ...ReactRouterPropTypes,
};

export default CategoryPage;
