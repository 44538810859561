import clsx from 'clsx';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ImageCrop, ImageSize, PostPropType } from 'consts';
import { getImageUrl, getMoreButtonText } from 'utils';
import { PostLink } from 'view/base';

import './Post.scss';

const Post = ({
  className, post, imageSize, /* defaultCategoryColor, showCategory, */ cropId,
}) => {
  // const { categories } = useCategories();
  const {
    title, lead, image, imageMediaId, imageSecondary, imageSecondaryMediaId, imageStyles, slug, redirectUrl, segment,
  } = post;

  /*
  const realCategory = postCategory || category;

  const displayDate = getDisplayDate(approvedAt || updatedAt || createdAt);
  const renderDate = !!displayDate;

  const renderCategory = showCategory && realCategory && categories;
  const categoryColor = (realCategory && categories && calcCategoryColor(realCategory, categories)) || defaultCategoryColor;
*/
  const images = [image || imageMediaId, imageSecondary || imageSecondaryMediaId];
  const imageUrl = image || imageMediaId ? getImageUrl(images, imageSize, { imageStyles, cropId }) : undefined;

  return (
    <article className={clsx('article-container', className)} style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="p-4 article-card">
        <PostLink className="article-title" slug={slug} segment={segment} redirectUrl={redirectUrl}>{title}</PostLink>
        <div className="pt-4 article-description">{lead.slice(0, 220)}</div>
        <PostLink className="article-more" slug={slug} segment={segment} redirectUrl={redirectUrl}>
          <span>&#8212;&#8212;</span> {getMoreButtonText()}
        </PostLink>
      </div>
    </article>
  );
};

Post.propTypes = {
  className: PropTypes.string,
  post: PostPropType.isRequired,
  imageSize: PropTypes.number,
  cropId: PropTypes.oneOf(map(ImageCrop)),
};

Post.defaultProps = {
  className: '',
  imageSize: ImageSize.SMALL,
  cropId: ImageCrop.SQUARE,
};

export default Post;
