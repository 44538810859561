import React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { ThematicPages } from 'consts';

const { REACT_APP_VERSION, REACT_APP_BUILD_DATE } = process.env;
const buildDate = REACT_APP_BUILD_DATE ? new Date(+REACT_APP_BUILD_DATE * 1000).toLocaleString() : '';

const MobileFooter = () => (
  <div className="d-block d-sm-none footer-mobile">
    <Row>
      <Col xs={12}>
        <div className="footer-left csbo-logo">
          <a className="csbo-link" href="https://csalad.hu/rolunk" target="_blank" rel="noopener noreferrer">
            <img src="/resources/images/csbo-logo.svg" title="Családbarát Ország Nonprofit Közhasznú Kft." alt="Családbarát Ország Nonprofit Közhasznú Kft." />
            <span className="logo-title">CSALÁDBARÁT<br />MAGYARORSZÁG<br />KÖZPONT</span>
          </a>
          <div className="version">{REACT_APP_VERSION} ({buildDate})
          </div>
        </div>
      </Col>
      <Col xs={12}>
        <div className="footer-center csbo-info">
          Családbarát Magyarország Központ <br />Nonprofit Közhasznú Kft.<br />
          1134 Budapest, Tüzér utca 33-35.<br />ugyfelszolgalat@csalad.hu<br />
          <br />
          Cégjegyzék szám: 01-09-283975<br />
          Nyilvántartó Bíróság:<br />Fővárosi Törvényszék Cégbírósága<br />
        </div>
      </Col>
      <Col xs={12} className="mt-5">
        <div className="footer-right impressum">
          <Stack gap={4}>
            <div>
              <Stack direction="horizontal" gap={3}>
                <a href="https://www.facebook.com/csalad.hu">
                  <img className="social-icons" src="/resources/images/facebook-f.svg" alt="Facebook" title="Facebook" />
                </a>
                <a href="https://www.instagram.com/csalad_hu/">
                  <img className="social-icons" src="/resources/images/instagram.svg" alt="Instagram" title="Instagram" />
                </a>
                <a href="https://www.youtube.com/channel/UCchrbl1lF0h7-aSNxNJctIg">
                  <img className="social-icons" src="/resources/images/youtube.svg" alt="Youtube" title="Youtube" />
                </a>
                <NavLink to={ThematicPages.Impressum.path} className="impresszum-link">Impresszum</NavLink>
              </Stack>
            </div>
            <div className="copyright">
              Copyright @ 2021 Családbarát Magyarország
            </div>
          </Stack>
        </div>
      </Col>
    </Row>
  </div>
);

export default MobileFooter;
