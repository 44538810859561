import axios from 'axios';
import { each, mapValues, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import { servicesConfig, BASE_URL } from 'consts';
import { provider } from 'utils';
import { useAxiosCache as axiosCacheGlobal } from 'utils/axios-cache';

const ServiceProvider = ({
  axiosCache, headers, preview, children,
}) => {
  const services = useMemo(() => {
    const mapCommonConfig = ({ url, noCache, ...cfg } = {}) => merge({}, cfg, {
      url: BASE_URL + url,
      api: noCache ? axios : axiosCache,
      headers,
    });

    // add 'preview' into params (will be used by services which has 'preview' param defined)
    const mapCommonParams = (serviceFn) => (params = {}, cfg = {}) => (
      serviceFn(preview ? { ...params, preview } : params, cfg)
    );

    const result = provider(mapValues(servicesConfig, mapCommonConfig));
    each(result, (serviceFn, id) => {
      const newServiceFn = mapCommonParams(serviceFn);
      newServiceFn.config = serviceFn.config;
      newServiceFn.id = serviceFn.id;
      result[id] = newServiceFn;
    });

    return result;
  }, [preview, axiosCache, headers]);

  return (
    <ServiceProvider.Context.Provider value={services}>
      {children}
    </ServiceProvider.Context.Provider>
  );
};

ServiceProvider.Context = createContext([false, () => {}]);

ServiceProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  axiosCache: PropTypes.func,
  preview: PropTypes.bool,
  headers: PropTypes.shape({}),
};

ServiceProvider.defaultProps = {
  preview: false,
  axiosCache: axiosCacheGlobal,
  headers: {},
};

export const useServices = () => {
  const services = useContext(ServiceProvider.Context);
  if (!services) {
    throw new Error('Error calling `useService`: a parent ServiceProvider component must be present!');
  }

  return services;
};

export const useCachedService = (service, params, config) => service(params, config);

export const useService = (service, options = { autoStart: false, config: {}, params: {} }) => {
  const [state, setState] = useState({ loading: false });

  const startRequest = useCallback(({ cancelTokenSource, onBeforeStart }) => {
    setState((prevState) => {
      if (onBeforeStart?.(prevState) === false) {
        return prevState;
      }

      return {
        ...prevState,
        loading: true,
        cancel: cancelTokenSource?.cancel,
      };
    });
  }, []);

  const endRequest = useCallback(({ response, error, transformFn }) => {
    setState((prevState) => {
      const { response: res, error: err } = transformFn?.({
        response,
        prevResponse: prevState.response,
        error,
        prevError: prevState.error,
      }) || { response, error };

      return {
        ...prevState,
        loading: false,
        cancel: null,
        cancelled: error ? axios.isCancel(err) : null,
        response: res,
        data: res?.data,
        error: err || null,
      };
    });
  }, []);

  const refetch = useCallback(async (params, config) => {
    try {
      const cancelTokenSource = axios.CancelToken.source();
      startRequest({ cancelTokenSource });

      const response = await service(params, { ...config, cancelToken: cancelTokenSource.token });

      endRequest({ response });

      return { response };
    } catch (error) {
      const { response } = error;
      endRequest({ response, error });

      return { error, response };
    }
  }, [endRequest, service, startRequest]);

  useEffect(() => {
    const { autoStart, params, config } = options;
    if (!autoStart || state.loading) {
      return undefined;
    }
    refetch(params, config);

    return () => {
      if (state.cancel) {
        state.cancel();
      }
    };
  }, [state, refetch, options]);

  return {
    state, refetch, options, startRequest, endRequest,
  };
};

export default ServiceProvider;
