import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import './VedjegyKereso.scss';

const VedjegyKereso = ({ className }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show && selectedCompany?.id) {
      axios.get(`/api/vedjegy/v1/getCegInfo/${selectedCompany?.id}`)
        .then((r) => {
          if (r?.data && r.data[0]) {
            setSelectedCompany(r.data[0]);
          }
        })
      .catch(err => console.log(err)) // eslint-disable-line
    } else {
      setSelectedCompany(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleClose = () => setShow(false);

  useEffect(() => {
    window.fbq('track', 'VedjegyKereso');
    axios.get('/api/vedjegy/v1/getCegek')
      .then((r) => {
        if (r?.data) {
          setCompanies(Object.values(r.data));
        } else {
          setCompanies([]);
        }
      })
      .catch(err => console.log(err)) // eslint-disable-line
  }, []);

  const filterCompanies = (cs) => {
    if (searchText) {
      const regexPattern = /[^A-Za-z0-9]/g;
      const searchTextForSearch = searchText.replace(regexPattern, '').toLowerCase();

      return cs.filter((c) => {
        const name = c?.szervezet_neve + c?.szervezet_cim;
        const nameForSearch = name.replace(regexPattern, '').toLowerCase();

        if (nameForSearch.includes(searchTextForSearch)) {
          return true;
        }

        return false;
      }).slice(0, 10);
    }
    return [];
  };

  return (
    <>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header style={{ padding: '20px' }} closeButton>
          <Modal.Title style={{ fontSize: '20px', fontWeight: 'bold' }}>{selectedCompany?.szervezet_neve}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '16px', padding: '20px' }}>
          <p style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: 0 }}>Cím</p>
          <p style={{ paddingTop: 0 }}>{selectedCompany?.szervezet_cim}</p>
          <p style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: 0 }}>Terület</p>
          <p style={{ paddingTop: 0 }}>{selectedCompany?.szervezet_terulet}</p>
          <p style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: 0 }}>Típus</p>
          <p style={{ paddingTop: 0 }}>{selectedCompany?.szervezet_tipus ?? '-'}</p>
        </Modal.Body>
      </Modal>

      <div className={clsx(className, 'vedjegy-kereso')}>

        <section className={clsx('pb-4', 'pb-sm-3', 'pb-lg-5')}>

          <Row className="pb-4 px-4 px-sm-3 px-lg-5">
            <Col xs={12} className="px-lg-4">
              <div className="kereso-title">Családbarát cég kereső</div>
            </Col>
          </Row>
          <Row className="px-4 px-sm-3 px-lg-5">
            <Col xs={12} className="px-lg-4">
              <div>
                <input
                  type="text"
                  style={{
                    fontSize: '16px',
                    margin: 0,
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #999',
                    backgroundColor: '#dfdfdf',
                    borderRadius: '3px',
                  }}
                  placeholder="Keresés.."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="pb-4 px-4 px-sm-3 px-lg-5">
            {
            // eslint-disable-next-line no-nested-ternary
            companies && companies?.length ? filterCompanies(companies).map((c) => (
              <Col key={c.id} xs={12} className="px-lg-4">
                <div
                  role="button"
                  onClick={() => {
                    setSelectedCompany(c);
                    setShow(true);
                  }}
                  style={{
                    fontSize: '16px',
                    padding: '10px',
                    cursor: 'pointer',
                    border: '1px solid #999',
                    borderRadius: '3px',
                  }}
                >{c.szervezet_neve} - {c.szervezet_cim}
                </div>
              </Col>
            )) : searchText ? (
              <Col xs={12} className="px-lg-4">
                <div style={{
                  fontSize: '16px',
                  padding: '10px',
                  color: '#ddd',
                  border: '1px solid #999',
                  borderRadius: '3px',
                }}
                >
                  Nincs találat.
                </div>
              </Col>
            ) : null
}
          </Row>
        </section>

      </div>
    </>
  );
};

VedjegyKereso.propTypes = {
  className: PropTypes.string,
};

VedjegyKereso.defaultProps = {
  className: '',
};

export default VedjegyKereso;
