import { each, identity } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

const addTrimmed = (result, text, ctx) => {
  if (ctx.trimmed) {
    return;
  }

  const trimmedText = [];
  each(text.split(' '), (word) => {
    if (ctx.maxLength && ctx.length > ctx.maxLength) {
      trimmedText.push('…');
      ctx.trimmed = true;
      return false;
    }
    trimmedText.push(word);
    ctx.length += word.length + 1;
    return true;
  });

  result.push({
    text: trimmedText.join(' '),
  });
};
const parseLead = (lead, maxLength) => {
  const result = [];
  const ctx = {
    lastPos: 0,
    length: 0,
    maxLength,
    trimmed: false,
  };
  (lead || '').replace(/\[(.+?)\|(=)?(.+?)\]/g, (part, text, prefix, link, idx, str) => {
    if (!ctx.trimmed) {
      const pre = str.substr(ctx.lastPos, idx - ctx.lastPos);
      ctx.lastPos = idx + part.length;
      if (pre) {
        addTrimmed(result, pre, ctx);
      }

      result.push({
        text,
        link,
        blank: prefix !== '=' ? true : undefined,
      });
      ctx.length += text.length;
    }
    return part;
  });

  const post = lead.substr(ctx.lastPos);
  if (post) {
    addTrimmed(result, post, ctx);
  }

  return result;
};
window.parseLead = parseLead;

const PostLead = ({
  lead, component: Component, maxLength, ...props
}) => {
  const onLinkClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const parsedLead = parseLead(lead, maxLength);

  return (
    <Component {...props}>
      {parsedLead.map(({ text, blank, link }, idx) => {
        if (link) {
          return <a key={identity(idx)} href={link} target={blank ? '_blank' : undefined} rel="noreferrer" onClick={onLinkClick}>{text}</a>;
        }

        return text;
      })}
    </Component>
  );
};

PostLead.propTypes = {
  maxLength: PropTypes.number,
  lead: PropTypes.string.isRequired,
  component: PropTypes.elementType,
};

PostLead.defaultProps = {
  maxLength: null,
  component: 'div',
};

export default memo(PostLead);
