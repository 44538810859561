import clsx from 'clsx';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import {
  TagPropType, ImagePropType, ImageSize, ImageCrop, ImageCropRatio, TileType, CategoryPropType, LEAD_MAX_LEN,
} from 'consts';
import { useCategories, useSegment } from 'contexts';
import {
  calcCategoryColor, getImageTitle, getImageUrl, getMoreButtonText,
} from 'utils';
import { PostLead, PostLink } from 'view/base';

import './PostTile.scss';

const PostTile = ({
  slug, segment, title, lead, image, imageMediaId, imageSecondary, imageSecondaryMediaId, imageStyles, redirectUrl, tileType,
  categories, category: categoryProp,
}) => {
  const { activeSegment } = useSegment();
  const category = categoryProp || categories?.[0];
  const { categories: allCategories } = useCategories();
  const categoryColor = useMemo(
    () => category && categories && calcCategoryColor(category, allCategories),
    [allCategories, categories, category],
  );
  const images = [image || imageMediaId, imageSecondary || imageSecondaryMediaId];

  if ([TileType.BANNER, TileType.WIDE].includes(tileType)) {
    const imageMobileUrl = getImageUrl(images, ImageSize.NORMAL, { imageStyles, cropId: ImageCrop.NARROW });
    const imageMobileBannnerUrl = getImageUrl(images, ImageSize.NORMAL, { imageStyles, cropId: ImageCrop.SQUARE });
    const imageNonMobileUrl = getImageUrl(images, ImageSize.NORMAL, { imageStyles, cropId: ImageCrop.WIDE });

    return (
      <PostLink className="post-tile lead-article-container" slug={slug} segment={segment || activeSegment} redirectUrl={redirectUrl}>
        <div className="responsive mobile">
          {/* tileType === TileType.WIDE && <div className="gradient-mask" style={{ '--aspect-ratio': ImageCropRatio[ImageCrop.NARROW] }} /> */}
          {/* <div className="hover-mask" style={{ '--aspect-ratio': ImageCropRatio[ImageCrop.NARROW] }} /> */}
          <img src={tileType === TileType.BANNER ? imageMobileBannnerUrl : imageMobileUrl} alt={getImageTitle(images)} title={getImageTitle(images)} />

          <div className={clsx('lead-article-card', tileType === TileType.BANNER ? 'd-none' : 'd-block')}>
            <div className="lead-article-title">{title}</div>
            <PostLead className="lead-article-description" lead={lead} maxLength={LEAD_MAX_LEN} />
            <div className="lead-article-more">
              <span className="line" /> {getMoreButtonText()}
            </div>
          </div>
        </div>
        <div className={clsx('responsive tablet', { 'limit-height': tileType === TileType.WIDE })}>
          <div className={tileType === TileType.WIDE ? 'gradient-mask' : ''} style={{ '--aspect-ratio': ImageCropRatio[ImageCrop.WIDE] }} />
          {/* <div className="hover-mask" style={{ '--aspect-ratio': ImageCropRatio[ImageCrop.WIDE] }} /> */}
          <img src={imageNonMobileUrl} alt={getImageTitle(images)} title={getImageTitle(images)} />
          <div className={clsx('title-holder', tileType === TileType.BANNER ? 'd-none' : 'd-block')}>
            <div className="lead-article-title">{title}</div>
            <div className="lead-article-more">
              <span className="line" /> {getMoreButtonText()}
            </div>
          </div>
          <div className={clsx('lead-article-description', tileType === TileType.BANNER ? 'd-none' : 'd-block')}>{lead.slice(0, 220)}</div>
        </div>
        <div className={clsx('responsive desktop', { 'limit-height': tileType === TileType.WIDE })}>
          <div className={tileType === TileType.WIDE ? 'gradient-mask' : ''} style={{ '--aspect-ratio': ImageCropRatio[ImageCrop.WIDE] }} />
          {/* <div className="hover-mask" style={{ '--aspect-ratio': ImageCropRatio[ImageCrop.WIDE] }} /> */}
          <img src={imageNonMobileUrl} alt={getImageTitle(images)} title={getImageTitle(images)} />
          <div className={clsx('title-holder', tileType === TileType.BANNER ? 'd-none' : 'd-block')}>
            <div className="lead-article-title">{title}</div>
            <div className="lead-article-more">
              <span className="line" /> {getMoreButtonText()}
            </div>
          </div>
          <PostLead
            className={clsx('lead-article-description', tileType === TileType.BANNER ? 'd-none' : 'd-block')}
            lead={lead}
            maxLength={LEAD_MAX_LEN}
          />
        </div>
      </PostLink>
    );
  }

  const imageUrl = getImageUrl(images, ImageSize.SMALL, { imageStyles, cropId: ImageCrop.SQUARE });

  return (
    <PostLink className="post-tile article-container" slug={slug} segment={segment || activeSegment} redirectUrl={redirectUrl}>
      <div className="post-image">
        <div className="post-image-inner">
          <div className="hover-mask" />
          <img src={imageUrl} alt={title} title={title} />
        </div>
      </div>
      <div className="article-card">
        <div className="article-title">{title}</div>
        <PostLead className="article-description" lead={lead} maxLength={LEAD_MAX_LEN} />
        <div className="article-more">
          <span className="line" style={{ borderColor: categoryColor }} /> {getMoreButtonText()}
        </div>
      </div>
    </PostLink>
  );
};

PostTile.propTypes = {
  // className: PropTypes.string,
  id: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
  slug: PropTypes.string.isRequired,
  segment: PropTypes.string,
  title: PropTypes.string.isRequired,
  lead: PropTypes.string,
  content: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  image: ImagePropType,
  imageMediaId: PropTypes.number,
  imageSecondary: ImagePropType,
  imageSecondaryMediaId: PropTypes.number,
  // imageSecondary: ImagePropType,
  // imageSecondaryMediaId: PropTypes.number,
  imageStyles: PropTypes.string,
  source: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  redirectUrl: PropTypes.string,
  tags: PropTypes.arrayOf(TagPropType), // eslint-disable-line react/no-unused-prop-types
  categories: PropTypes.arrayOf(CategoryPropType),
  category: CategoryPropType,
  attributes: PropTypes.shape({
    videoUrl: PropTypes.string,
  }),
  tileType: PropTypes.oneOf(map(TileType)),
};

PostTile.defaultProps = {
  // className: '',
  segment: undefined,
  lead: '',
  content: '',
  source: '',
  redirectUrl: '',
  tags: [],
  category: null,
  categories: [],
  image: null,
  imageMediaId: undefined,
  imageSecondary: null,
  imageSecondaryMediaId: undefined,
  imageStyles: undefined,
  attributes: {},
  tileType: TileType.SQUARE,
};

export default PostTile;
