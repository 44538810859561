// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import { isPlainObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Container, Navbar, Nav, Offcanvas, Button,
} from 'react-bootstrap';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { IS_BROWSER } from 'consts';
import { useToggledCategory } from 'contexts';
import { CloseIcon, HamburgerIcon } from 'view/base';
import { CategoryMenu, SearchForm } from 'view/components';
import CTAElement from 'view/components/CategoryMenu/CTAElement';

import './Header.scss';

const toggledInitState = {
  navbar: false,
  hamburger: false,
  search: false,
  category: null,
};

const Header = ({ className, onLogoClick }) => {
  const { sz2020 } = useToggledCategory();

  const [toggled, setToggled] = useState(toggledInitState);

  const onToggledChange = useCallback((dropdown) => (value) => {
    setToggled((prevToggled) => ({
      ...prevToggled,
      [dropdown]: isPlainObject(value) ? !prevToggled[dropdown] : value,
    }));
  }, []);
  const onToggledClose = useCallback((dropdown) => (/* event */) => {
    setToggled((prevToggled) => ({
      ...prevToggled,
      [dropdown]: false,
    }));
  }, []);

  const { pathname, search } = useLocation();
  useEffect(() => {
    if (!IS_BROWSER) {
      return;
    }
    window.scrollTo(0, 0);
    setToggled((prevToggled) => ({
      ...prevToggled,
      search: false,
      navbar: false,
      hamburger: false,
    }));
  }, [pathname, search]);

  const classString = sz2020 ? 'header sz2020' : 'header';

  return (
    <Navbar expand={false} expanded={toggled.navbar} className={clsx(classString, className)} onToggle={onToggledChange('navbar')}>
      <div className="d-block d-md-none full-width-cta"><CTAElement /></div>
      {toggled.search ? (
        <div className="search-header">
          <Nav.Link as={Button} className="search-close" onClick={onToggledClose('search')}><CloseIcon /></Nav.Link>
          <div className="search-center" />
          <SearchForm />
        </div>
      ) : (
        <Container fluid>
          <Navbar.Brand className="csalad-logo" as={NavLink} to="/" onClick={onLogoClick}>
            <img src="/resources/images/csalad.hu.logo_202406.png" alt="csalad.hu logo" title="csalad.hu logo" />
            <span>csalad.hu</span>
          </Navbar.Brand>
          <CategoryMenu
            className="d-none d-sm-block"
            toggled={toggled}
            onToggledChange={onToggledChange}
            onToggledClose={onToggledClose}
          />
          <div className="d-sm-none d-inline-block">
            <Nav.Link className="d-inline-block mob-nav-en" as={NavLink} to="/en">
              <img src="/resources/images/EN.png" alt="English version" title="English version" />
            </Nav.Link>

            <div className="d-inline-block d-sm-none">

              <Navbar.Toggle className="d-block d-sm-none hamburger-menu" aria-controls="offcanvasNavbar">
                <HamburgerIcon />
              </Navbar.Toggle>
              <Navbar.Offcanvas
                className="d-block d-sm-none"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
              >
                <Offcanvas.Header className="justify-content-end" closeButton />
                <Offcanvas.Body>
                  <CategoryMenu
                    toggled={toggled}
                    onToggledChange={onToggledChange}
                    onToggledClose={onToggledClose}
                  />
                  <SearchForm mobile />
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          </div>
        </Container>
      )}
    </Navbar>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onLogoClick: PropTypes.func,
};

Header.defaultProps = {
  className: '',
  onLogoClick: undefined,
};

export default Header;
