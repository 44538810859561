import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const ArrowForwardIcon = ({ className }) => (
  <svg className={clsx('arrow-forward-icon', className)} viewBox="0 0 24 24">
    <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
);

ArrowForwardIcon.propTypes = {
  className: PropTypes.string,
};

ArrowForwardIcon.defaultProps = {
  className: '',
};
export default ArrowForwardIcon;
