/* eslint-disable */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './index.scss';

const options = [
  {
    id: '001',
    title: 'Amigos a gyerekekért Alapítvány',
    color: 'rgba(255, 1, 0, 1)',
  },
  {
    id: '002',
    title: 'Sütni Jó! Alapítvány',
    color: 'rgba(6, 157, 255, 1)',
  },
  {
    id: '003',
    title: 'Szentendrei ÁRVÁCSKA Állatvédő Egyesület',
    color: 'rgba(0, 212, 0, 1)',
  },
  {
    id: '004',
    title: 'Tatabányai 4x4 Terepjárós, Polgári Védelmi és Önkéntes Tűzoltó Egyesület',
    color: 'rgba(213, 64, 255, 1)',
  },
];


const Votes = () => {
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedPrivacy, setSelectedPrivacy] = useState(false);
  const [voted, setVoted] = useState(window?.localStorage?.getItem('voted_onkent_2024') ?? false);
  const [votes, setVotes] = useState(null);

  useEffect(() => {
    if (voted) {
      axios.get('/api/vote')
        .then((r) => {
          if (r?.data?.result) {
            setVotes(r.data.result);
          }
        })
        .catch(err => console.log(err)) // eslint-disable-line
    }
  }, [voted]);

  const vote = () => {
    if (!selectedPrivacy) {
      alert('A Szavazási szabályzat elfogadása kötelező.'); // eslint-disable-line
    } else if (!selectedItem) {
      alert('Alapítvány választása kötelező.'); // eslint-disable-line
    } else {
      axios.post('/api/vote',
        {
          identity_string: 'unused',
          vote_string: selectedItem,
        }).then(() => {
          window.localStorage.setItem('voted_onkent_2024', true);
          setVoted(true);
        });
    }
  };

  const getPercent = (o) => {
    let all = 0;
    votes?.map((v) => {
      all += v?.count;
      return null;
    });
    const current = votes?.find((v) => (v.vote_string === o.id))?.count ?? 0;

    if (!all) {
      return 0;
    }
    if (!current) {
      return 0;
    }
    return Math.round((current / all) * 100);

  };

  return (
    <div className="pmob">
      <h3 className="onkent-jottem-vote">Önként Jöttem díj 2024</h3>
      <table className={voted ? "onkent-jottem-vote voted" : "onkent-jottem-vote"}>
        <tbody>
          {options.map((o) => (
            <tr key={o.id} style={{ cursor: 'pointer' }} onClick={() => setSelectedItem(o.id)}>
              {!voted ? (
                <>
                  <td style={{ width: '10px' }}>
                    <input
                      readOnly
                      type="checkbox"
                      checked={o.id === selectedItem}
                    />
                  </td>
                  <td>{o.title}</td>
                </>
              ) : (
                <>
                  <td style={{ minWidth: '150px' }}>{o.title}</td>
                  <td>
                    <span className="percentBar" style={{
                      width: '300px',
                      display: 'inline-block',
                      height: '10px',
                      background: `linear-gradient(90deg, ${o.color} 0%, ${o.color} ${getPercent(o)}%, rgba(196,196,196,1) ${getPercent(o)}%, rgba(196,196,196,1) 100%)`,
                      content: '',
                    }} />
                  </td>
                  <td style={{ width: '70px' }}>
                    <span>{getPercent(o)}%</span>
                  </td>
                </>
              )}

            </tr>
          ))}
        </tbody>
      </table>
      {!voted ?
        (
          <>
            <table className="onkent-jottem-vote-privacy">
              <tbody>
                <tr>
                  <td style={{ width: '10px' }}>
                    <input
                      style={{ cursor: 'pointer' }}
                      type="checkbox"
                      onChange={() => setSelectedPrivacy(!selectedPrivacy)}
                      checked={selectedPrivacy}
                    />
                  </td>
                  <td>A <a target='_blank' href="https://csalad.hu/api/files/12970-2024-onk-dij-szavazasi-szabalyzat">Szavazási szabályzat</a> rendelkezéseit magamra nézve kötelezőnek ismerem el,
                    továbbá nyiltakozom, hogy 18. életévemet betöltöttem.
                  </td>
                </tr>
              </tbody>
            </table>
            <span className="voteButton" role="button" onClick={() => vote()} >
              SZAVAZOK
            </span>
          </>
        ) :
        (
          <>
            <table className="onkent-jottem-vote-privacy">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    Köszönjük szavazatát.
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
    </div>

  );
};

export default Votes;