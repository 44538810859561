import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { NavDropdown } from 'react-bootstrap';

import { CategoryStatus } from 'consts';
import { ArrowForwardIcon } from 'view/base';

import CategoryLink from './CategoryLink';
import CategoryMenuList from './CategoryMenuList';

const CategoryList = ({
  rootCategories, categories, rootUrl, toggled, onCategoryToggle, desktop,
}) => (
  <>
    {rootCategories.map((category) => {
      const {
        name, id, attributes, slug,
      } = category;
      const subCategories = categories.filter(({ parentId, status }) => parentId === id && status !== CategoryStatus.HIDDEN);
      if (subCategories.length) {
        return (
          <NavDropdown
            key={id}
            show={+toggled.category === +id}
            onToggle={onCategoryToggle(id)}
            title={desktop ? (
              <>
                <span>{name}</span>
                <span className={clsx('toggle-button', { opened: +toggled.category === +id })}>
                  <ArrowForwardIcon className="toggle-icon" />
                </span>
              </>
            ) : name}
            className={clsx('menu-big-text', { 'desktop-dropdown': desktop })}
            id="offcanvasNavbarDropdown"
          >
            <CategoryMenuList
              allCategories={categories}
              categories={subCategories}
              prefixUrl={attributes?.displayInHeader ? attributes?.target || `/${slug}` : undefined}
              rootUrl={rootUrl}
            />
          </NavDropdown>
        );
      }

      return <CategoryLink key={id} category={category} className="menu-big-text" rootUrl={rootUrl} />;
    })}
  </>
);

CategoryList.propTypes = {
  rootCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggled: PropTypes.shape({ category: PropTypes.number }).isRequired,
  onCategoryToggle: PropTypes.func.isRequired,
  rootUrl: PropTypes.string,
  desktop: PropTypes.bool,
};

CategoryList.defaultProps = {
  rootUrl: undefined,
};

export default CategoryList;
