import { useCallback, useState } from 'react';

import { sleep } from 'utils';

const useTransition = ({ enteringTimeout = 300, leavingTimeout = 300 } = {}) => {
  const [state, setState] = useState({ entering: false, leaving: false, visible: false });

  return {
    ...state,

    enter: useCallback(async () => {
      setState((prevState) => ({ ...prevState, leaving: false, entering: true }));
      await sleep(enteringTimeout);
      setState((prevState) => ({
        ...prevState, leaving: false, entering: false, visible: true,
      }));
    }, [enteringTimeout]),

    leave: useCallback(async () => {
      setState((prevState) => ({
        ...prevState, leaving: true, entering: false, visible: false,
      }));
      await sleep(leavingTimeout);
      setState((prevState) => ({ ...prevState, leaving: false, entering: false }));
    }, [leavingTimeout]),
  };
};

export default useTransition;
