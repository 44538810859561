import clsx from 'clsx';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { CategoryStatus } from 'consts';
import { useToggledCategory } from 'contexts';
import { Button, CloseIcon, HamburgerIcon } from 'view/base';

import CTAElement from './CTAElement';
import CategoryLink from './CategoryLink';
import CategoryList from './CategoryList';
import './CategoryMenu.scss';

const CategoryMenu = ({
  className, toggled, onToggledClose, onToggledChange,
}) => {
  const { categories, rootUrl } = useToggledCategory();
  const ref = useRef();

  const rootCategories = useMemo(() => filter(categories, ({ parentId, status }) => !parentId && status !== CategoryStatus.HIDDEN), [categories]);
  const headerCategories = useMemo(() => rootCategories.filter(({ attributes }) => attributes?.displayInHeader), [rootCategories]);

  const { pathname } = useLocation();
  const firstActive = pathname === '/' ? true : undefined;

  const onCategoryToggle = useCallback((id) => (isOpen, { source }) => {
    if (source === 'rootClose') {
      return;
    }
    onToggledChange('category')(isOpen ? id : null);
  }, [onToggledChange]);

  return (
    <>
      <div ref={ref} className={clsx('category-menu d-block d-sm-none', className)}>
        <ul className="menu-list mobile">
          {categories && (
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <CategoryList
                rootCategories={rootCategories}
                categories={categories}
                onCategoryToggle={onCategoryToggle}
                toggled={toggled}
                rootUrl={rootUrl}
              />
            </Nav>
          )}
        </ul>
      </div>
      <Nav className="category-menu d-none d-sm-flex">
        <div className="d-none d-md-block"><CTAElement /></div>

        {headerCategories?.map((category, idx) => (
          <CategoryLink key={category.id} className="nav-item menu-big-text" category={category} active={(firstActive && !idx) || undefined} />
        ))}
        <NavDropdown
          className="nav-item hamburger no-hover"
          show={toggled.hamburger}
          onToggle={onToggledChange('hamburger')}
          title={<HamburgerIcon />}
          id="menuDropdownOffCanvas"
        >
          <Nav.Link as={Button} className="close-button" onClick={onToggledClose('hamburger')}><CloseIcon /></Nav.Link>
          {categories && (
            <CategoryList
              desktop
              rootCategories={rootCategories}
              categories={categories}
              onCategoryToggle={onCategoryToggle}
              toggled={toggled}
              rootUrl={rootUrl}
            />
          )}
        </NavDropdown>
        <Nav.Link className="nav-item search no-hover" onClick={onToggledChange('search')}>
          <img src="/resources/images/search.png" alt="Keresés" title="Keresés" />
        </Nav.Link>
        <Nav.Link className="nav-item en-btn no-hover" as={NavLink} to="/en">
          <img src="/resources/images/EN.png" alt="English version" title="English version" />
        </Nav.Link>
      </Nav>
    </>
  );
};

CategoryMenu.propTypes = {
  className: PropTypes.string,
  toggled: PropTypes.shape({
    family: PropTypes.bool,
    hamburger: PropTypes.bool,
    search: PropTypes.bool,
    navbar: PropTypes.bool,
  }).isRequired,
  onToggledClose: PropTypes.func,
  onToggledChange: PropTypes.func,
};

CategoryMenu.defaultProps = {
  className: '',
  onToggledClose: null,
  onToggledChange: null,
};

export default CategoryMenu;
