import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { getPostUrl, ChildrenPropType } from 'consts';
import { useSegment } from 'contexts';

const PostLink = ({
  slug, redirectUrl, segment, children, ...linkProps
}) => {
  const { activeSegment } = useSegment();

  if (redirectUrl) {
    const blank = redirectUrl[0] === '+';
    return <a key={slug} href={blank ? redirectUrl.substr(1) : redirectUrl} target={blank ? '_blank' : undefined} rel="noopener noreferrer" {...linkProps}>{children}</a>;
  }

  return <NavLink key={slug} to={getPostUrl({ slug, segment: segment || activeSegment })} {...linkProps}>{children}</NavLink>;
};

PostLink.propTypes = {
  slug: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  segment: PropTypes.string,
  children: ChildrenPropType.isRequired,
};

PostLink.defaultProps = {
  redirectUrl: '',
  segment: undefined,
};

export default PostLink;
