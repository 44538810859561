import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { getSearchUrl } from 'consts';
import { getQueryParam } from 'utils';
import { Button } from 'view/base';

import './SearchForm.scss';

const SearchForm = ({ className, mobile }) => {
  const history = useHistory();
  const { search } = useLocation();
  const searchQuery = getQueryParam(search, 's') || '';
  const [query, setQuery] = useState(searchQuery);
  const onInputChange = useCallback((event) => setQuery(event.target.value), []);
  const onSearchSubmit = useCallback((ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    history.push(getSearchUrl({ s: query }));
  }, [history, query]);

  if (mobile) {
    return (
      <form className={clsx('search-form mobile', className)} onSubmit={onSearchSubmit}>
        <img src="/resources/images/search.png" alt="" />
        <input type="text" placeholder="Keresés" autoComplete="off" name="query" value={query} onChange={onInputChange} />
      </form>
    );
  }

  return (
    <form className={clsx('search-form', className)} onSubmit={onSearchSubmit}>
      <input type="text" placeholder="Keresés" name="query" value={query} onChange={onInputChange} />
      <Nav.Link as={Button} type="submit" className="search-button">
        <img src="/resources/images/search-desktop.png" alt="Keresés" title="Keresés" />
      </Nav.Link>
    </form>
  );
};

SearchForm.propTypes = {
  ...ReactRouterPropTypes,
  className: PropTypes.string,
  mobile: PropTypes.bool,
};

SearchForm.defaultProps = {
  className: '',
  mobile: false,
};

export default SearchForm;
