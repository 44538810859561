import React from 'react';
import { Stack } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { ThematicPages } from 'consts';

const { REACT_APP_VERSION, REACT_APP_BUILD_DATE } = process.env;
const buildDate = REACT_APP_BUILD_DATE ? new Date(+REACT_APP_BUILD_DATE * 1000).toLocaleString() : '';

const DesktopFooter = () => (
  <div className="d-none d-sm-block">
    <Stack direction="horizontal" gap={2}>
      <div className="footer-left csbo-logo">
        <a className="csbo-link" href="https://csalad.hu/rolunk" target="_blank" rel="noopener noreferrer">
          <img src="/resources/images/csbo-logo.svg" title="Családbarát Ország Nonprofit Közhasznú Kft." alt="Családbarát Ország Nonprofit Közhasznú Kft." />
          <span className="logo-title">CSALÁDBARÁT<br />MAGYARORSZÁG<br />KÖZPONT</span>
        </a>
        <div className="version">{REACT_APP_VERSION} ({buildDate})</div>
      </div>
      <div className="footer-center csbo-info">
        Családbarát Magyarország Központ Nonprofit Közhasznú Kft.<br />
        1134 Budapest, Tüzér utca 33-35. - ugyfelszolgalat@csalad.hu<br />
        <a style={{ margin: 0 }} href="https://csalad.hu/resources/pdf/adatkezelesi_tajekoztato.pdf" target="_blank" rel="noreferrer">
          Ügyfélszolgálat Adatkezelési tájékoztató
        </a>
        Felnőttképzők nyilvántartásában szereplő nyilvántartási szám: B/2020/000851<br />
        Felnőttképzők nyilvántartásában szereplő engedélyszám: E/2021/000103<br />
        Cégjegyzék szám: 01-09-283975<br />
        Nyilvántartó Bíróság: Fővárosi Törvényszék Cégbírósága<br />
      </div>
      <div className="footer-right impressum">
        <Stack gap={4}>
          <Stack direction="horizontal" className="flex-row-reverse" gap={3}>
            <NavLink to={ThematicPages.Impressum.path} className="impresszum-link">Impresszum</NavLink>
            <a href="https://www.youtube.com/channel/UCchrbl1lF0h7-aSNxNJctIg">
              <img className="social-icons" src="/resources/images/youtube.svg" alt="Youtube" title="Youtube" />
            </a>
            <a href="https://www.instagram.com/csalad_hu/">
              <img className="social-icons" src="/resources/images/instagram.svg" alt="Instagram" title="Instagram" />
            </a>
            <a href="https://www.facebook.com/csalad.hu">
              <img className="social-icons" src="/resources/images/facebook-f.svg" alt="Facebook" title="Facebook" />
            </a>
          </Stack>
          <div className="copyright-text">
            Copyright @ 2021 Családbarát Magyarország
          </div>
        </Stack>
      </div>
    </Stack>
  </div>
);

export default DesktopFooter;
