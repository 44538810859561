import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ThematicPages } from 'consts';
import { Button } from 'view/base';

import './CookiePopup.scss';

const COOKIE_DELAY = 250;
const LS_COOKIE_KEY = 'CS_HU_COOKIES_ACCEPTED';

const isCookiesAccepted = () => {
  try {
    return JSON.parse(window.localStorage.getItem(LS_COOKIE_KEY)) || false;
  } catch (er) {
    return false;
  }
};

const CookiePopup = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isCookiesAccepted()) {
      return;
    }

    setTimeout(() => {
      setVisible(true);
    }, COOKIE_DELAY);
  }, []);

  const onAcceptClick = useCallback(() => {
    window.localStorage.setItem(LS_COOKIE_KEY, 'true');
    setVisible(false);
  }, []);

  return (
    <div className={clsx('cookie-popup', { visible })}>
      <div className="cookie-inner">
        <div className="cookie-message">
          Weboldalunkon sütiket használunk. Az oldalon az oldal működéséhez feltétlenük szükséges és munkamenet-támogató, az egyes
          felhasználói munkamenetek azonosítására szolgáló sütiket (cookies) használunk. Az oldalon alkalmazott funkcionális sütikről
          bővebb tájékoztatást <NavLink to={ThematicPages.CookiePolicy.path}>ide kattintva</NavLink> olvashat.
        </div>
        <div className="actions">
          <Button variant="dark" text outlined type="button" onClick={onAcceptClick}>RENDBEN</Button>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
