const ThematicPages = {
  FamilyProtection: {
    component: 'CategoryPage',
    path: '/csaladvedelmi-akcioterv',
    props: {
      categorySlug: 'csaladvedelmi-akcioterv',
    },
    sitemap: {
      changeFreq: 'daily',
    },
  },
  HomeSupport: {
    component: 'CategoryPage',
    path: '/otthonteremtesi-program',
    props: {
      imageSlug: 'otthonteremtesi-program-fooldal',
      categorySlug: 'otthonteremtesi-program',
      faqPostSlug: 'otthonteremtesi-program-gyik',
      excludedModules: ['/videok'],
    },
    sitemap: {
      changeFreq: 'daily',
    },
  },
  Impressum: {
    slug: 'impresszum',
    component: 'PostPage',
    props: { match: { params: { post: 'impresszum' } } },
    path: '/impresszum',
  },
  PrivacyInfo: {
    slug: 'adatvedelmi-tajekoztato',
    component: 'PostPage',
    props: { match: { params: { post: 'adatvedelmi-tajekoztato' } } },
    path: '/adatvedelmi-tajekoztato',
  },
  CookiePolicy: {
    slug: 'suti-adatkezelesi-tajekoztato',
    component: 'PostPage',
    props: { match: { params: { post: 'suti-adatkezelesi-tajekoztato' } } },
    path: '/suti-adatkezelesi-tajekoztato',
  },
  SubscriptionSuccess: {
    slug: 'feliratkozas',
    component: 'PostPage',
    props: {
      code: [
        '<!-- Global site tag (gtag.js) - Google Analytics -->',
        '<script async src="https://www.googletagmanager.com/gtag/js?id=UA-128426444-1"></script>',
        '<script>',
        '  window.dataLayer = window.dataLayer || [];',
        '  function gtag(){dataLayer.push(arguments);}',
        '  gtag("js", new Date());',
        '  gtag("config", "UA-128426444-1");',
        '</script>',
      ].join(''),
    },
    path: '/feliratkozas/:post',
    sitemap: false,
  },
  Survey: {
    slug: 'kerdoivek',
    component: 'PostPage',
    path: '/kerdoivek/:post',
    sitemap: false,
  },
};

export default ThematicPages;
