import { filter, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { SurveyType, SurveyTypeConfig } from 'consts';
import { Checkbox } from 'view/base';

import './SurveyQuestion.scss';

const SurveyQuestion = ({
  type, title, subtitle, answers, value, onChange, error,
}) => {
  const { minCount, maxCount } = SurveyTypeConfig[type] || SurveyTypeConfig.SINGLE;

  const onCheckboxChange = useCallback((args) => {
    const { value: answer, value: { questionIdx, key }, checked } = args;
    const selected = filter(value, (answerChecked, answerKey) => {
      const [answerQuestionIdx] = answerKey.split('-');
      return questionIdx === +answerQuestionIdx && answerChecked;
    });
    const count = selected.length;

    if (maxCount !== undefined && checked && count >= maxCount) {
      return maxCount === 1 ? onChange({ ...value, [key]: key, [selected[0]]: null }, { answer, checked }) : undefined;
    }

    if (minCount !== undefined && !checked && count <= minCount) {
      return undefined;
    }

    return onChange && onChange({ ...value, [key]: checked && key }, { answer, checked });
  }, [onChange, maxCount, minCount, value]);

  return (
    <div className="survey-question">
      <div className="question-title" dangerouslySetInnerHTML={{ __html: title }} /> {/* eslint-disable-line react/no-danger */}
      {subtitle && <div className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />} {/* eslint-disable-line react/no-danger */}
      <div className="separator" />
      <div className="answers">
        {answers.map((answer) => (
          <Checkbox key={answer.key} id={`answer-${answer.key}`} className="answer" value={answer} checked={!!value[answer.key]} onChange={onCheckboxChange}>{answer.text}</Checkbox>
        ))}
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

SurveyQuestion.propTypes = {
  type: PropTypes.oneOf(values(SurveyType)),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    questionIdx: PropTypes.number,
    text: PropTypes.string,
  })).isRequired,
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

SurveyQuestion.defaultProps = {
  type: SurveyType.SINGLE,
  subtitle: undefined,
  error: undefined,
};

export default SurveyQuestion;
