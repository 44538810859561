import { SegmentUrl, ThematicPages } from '@csalad-2021/common';
import { find } from 'lodash';

import { calcQueryParams, joinPath } from 'utils/path';

/*
const Path = {
  category: (slug = ':category', page = ':page?') => Path.thematic(slug) || `/kategoria/${encodeURI(slug)}${page ? `/${page}` : ''}`,
  post: '/cikk/:post',
  tag: '/cimke/:tag/:page?',
  search: (params) => `/kereses${calcQueryParams(params)}`,
};
*/

export const getThematicUrl = (slug) => {
  const thematicPage = find(ThematicPages, (page) => page.slug === slug);
  return thematicPage && thematicPage.path;
};

export const getSegmentUrl = (segment, defaultUrl = '/') => SegmentUrl[segment] || defaultUrl;

export const getPostUrl = ({ slug, segment }) => joinPath(getSegmentUrl(segment, '/cikk'), encodeURI(slug));
export const getPostRoute = (segment) => joinPath(getSegmentUrl(segment, '/cikk'), ':post');

export const getCategoryUrl = ({
  slug, page = '', segment = '', rootUrl = undefined, prefixUrl = undefined,
}) => (
  getThematicUrl(slug) ||
  (rootUrl && joinPath(rootUrl, encodeURI(slug), page ? encodeURI(page) : '')) ||
  joinPath(getSegmentUrl(segment, prefixUrl), 'kategoria', encodeURI(slug), page ? encodeURI(page) : '')
);
export const getCategoryRoute = (segment) => joinPath(getSegmentUrl(segment), 'kategoria', ':category', ':page?');

export const getTagUrl = ({ slug, page = '', segment = '' }) => joinPath(getSegmentUrl(segment), 'cimke', encodeURI(slug), encodeURI(page));
export const getTagRoute = (segment) => joinPath(getSegmentUrl(segment), 'cimke', ':tag', ':page?');

export const getSearchUrl = (params) => joinPath('/kereses', params && calcQueryParams(params));
export const getSearchRoute = () => getSearchUrl();
