import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import './CTAElement.scss';

const btnStyle = {
  fontWeight: 'bold',
  color: '#fff',
};

const CTAElement = () => (
  <div
    className="ctaWrapper"
  >
    <Switch>
      <Route
        path="/kategoria/egyensulyban-podcast"
        component={() => (
          <a target="_blank" href="/resources/pdf/egyensulyban_nok_a_maganelet_es_a_munka_kozott.pdf">
            <span className="ctaElement egyensulyban" style={{ ...btnStyle, backgroundColor: '#655298', paddingRight: '10px' }}>
              Egyensúlyban kötet
              <img alt="letoltes" style={{ marginLeft: '15px', marginRight: '5px', maxHeight: '60%' }} src="/resources/images/egyensulyban_lila_icon.png" />
            </span>
          </a>
        )}
        exact
      />
      <Route
        path="/kategoria/csaladbarat-magyarorszag-kozpont"
        component={() => (
          <a target="_blank" href="/resources/pdf/CSBM_bemutatkozo2024_05.pdf">
            <span className="ctaElement" style={{ ...btnStyle, backgroundColor: '#d46159', paddingRight: '10px' }}>
              Bemutatkozás
              <img alt="letoltes" style={{ marginLeft: '10px' }} src="/resources/images/letoltes_ico.png" width="24px" />
            </span>
          </a>
        )}
        exact
      />
      <Route
        path="/kategoria/rolunk"
        component={() => (
          <a target="_blank" href="/resources/pdf/CSBM_bemutatkozo2024_05.pdf">
            <span className="ctaElement" style={{ ...btnStyle, backgroundColor: '#d46159', paddingRight: '10px' }}>
              Bemutatkozás
              <img alt="letoltes" style={{ marginLeft: '10px' }} src="/resources/images/letoltes_ico.png" width="24px" />
            </span>
          </a>
        )}
        exact
      />
      <Route
        path="/projektek/bemutatkozas"
        component={() => (
          <a target="_blank" href="/resources/pdf/CSBM_bemutatkozo2024_05.pdf">
            <span className="ctaElement" style={{ ...btnStyle, backgroundColor: '#d46159', paddingRight: '10px' }}>
              Bemutatkozás
              <img alt="letoltes" style={{ marginLeft: '10px' }} src="/resources/images/letoltes_ico.png" width="24px" />
            </span>
          </a>
        )}
        exact
      />

      <Route
        path="/"
        component={() => (
          <a target="_blank" href="/kalkulator">
            <span className="ctaElement" style={{ ...btnStyle, backgroundColor: '#469d8f' }}>Családtámogatási Kalkulátor</span>
          </a>
        )}
      />

    </Switch>
  </div>
);

export default CTAElement;
