import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const CloseIcon = ({ className }) => (
  <svg className={clsx('close-icon', className)} viewBox="0 0 100 100">
    <polygon points="77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 " />
  </svg>
);

CloseIcon.propTypes = {
  className: PropTypes.string,
};

CloseIcon.defaultProps = {
  className: '',
};
export default CloseIcon;
