import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ImageCrop, ImagePropType, ImageSize } from 'consts';
import { getImageUrl } from 'utils';

import PostLink from '../PostLink/PostLink';

import './PostImage.scss';

const PostImage = ({
  image, imageSecondary, imageStyles, imageSize, cropId, slug, segment, redirectUrl,
}) => {
  const className = `post-image post-image-${imageSize}-${cropId}`;
  const imageUrl = getImageUrl([image, imageSecondary], imageSize, { imageStyles, cropId });
  const innerImage = <div className="inner-image" style={{ backgroundImage: `url(${imageUrl})` }} />;

  if (slug) {
    return <PostLink className={className} slug={slug} segment={segment} redirectUrl={redirectUrl}>{innerImage}</PostLink>;
  }

  return <div className={className}>{innerImage}</div>;
};

PostImage.propTypes = {
  image: ImagePropType.isRequired,
  imageSecondary: ImagePropType,
  imageStyles: PropTypes.string,
  imageSize: PropTypes.number,
  cropId: PropTypes.oneOf(map(ImageCrop)),
  slug: PropTypes.string,
  segment: PropTypes.string,
  redirectUrl: PropTypes.string,
};

PostImage.defaultProps = {
  imageSecondary: null,
  imageStyles: {},
  imageSize: ImageSize.NORMAL,
  cropId: ImageCrop.WIDE,
  slug: undefined,
  segment: undefined,
  redirectUrl: undefined,
};

export default PostImage;
