import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { TileType } from 'consts';
import { usePostSearch } from 'contexts';
import { getQueryParam } from 'utils';
import { PostTile } from 'view/components';

import './SearchPage.scss';

const SearchPage = ({ className }) => {
  const { search } = useLocation();
  const [searchQuery, page] = getQueryParam(search, ['s', 'page']);
  const { posts } = usePostSearch(searchQuery, page);

  useEffect(() => {
    window.fbq('track', 'Search');
  }, []);

  return (
    <div className={clsx(className, 'search-page')}>
      <div className="search-title">Találatok</div>
      {posts && (
        <div className="posts">
          <Row>
            {(posts || []).map((post) => (
              <Col key={post.id} xs={12} sm={3}>
                <PostTile {...post} tileType={TileType.SQUARE} />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

SearchPage.propTypes = {
  className: PropTypes.string,
};

SearchPage.defaultProps = {
  className: '',
};

export default SearchPage;
