import PropTypes from 'prop-types';
import React from 'react';

import { BannerPosition, ImageSize } from 'consts';
import { useBannersDisplay } from 'contexts';
import { getImageUrl } from 'utils';
import { Link } from 'view/base';

import './Banners.scss';

const Banners = ({ positionId }) => {
  const { banners } = useBannersDisplay({ positionId });
  if (!banners?.length) {
    return '';
  }

  return (
    <div className="banners">
      {banners.map(({
        id, target, title, priority, imageMediaId,
      }) => (
        <Link key={id} to={target} title={title} style={{ order: priority }}>
          <img src={getImageUrl(imageMediaId, ImageSize.Banner)} title={title} alt={title} />
        </Link>
      ))}
    </div>
  );
};

Banners.propTypes = {
  positionId: PropTypes.oneOf(Object.keys(BannerPosition, (position) => BannerPosition[position])).isRequired,
};

Banners.defaultProps = {};

export default Banners;
