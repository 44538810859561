import { useEffect, useRef } from 'react';

const useScrollLoader = (loadMore) => {
  const scrollRef = useRef({ loading: false, prevScrollTop: 0 });
  useEffect(() => {
    const onScroll = async (/* event */) => {
      const { scrollHeight } = document.body;
      const { scrollY: scrollTop } = window;
      if (!scrollRef.current.loading && scrollTop > scrollHeight - 2 * window.innerHeight && scrollTop > scrollRef.current.prevScrollTop) {
        scrollRef.current.prevScrollTop = scrollTop;
        scrollRef.current.loading = true;
        await loadMore();
        scrollRef.current.loading = false;
      } else {
        scrollRef.current.prevScrollTop = scrollTop;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [loadMore]);
};

export default useScrollLoader;
