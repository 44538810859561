import clsx from 'clsx';
import {
  each, findIndex, isNil, trimStart, trimEnd,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Carousel } from 'react-responsive-carousel';
import {
  Redirect, NavLink, Route, Switch,
} from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import {
  getCategoryUrl,
  getTagUrl, HOST_URL, ImageCrop, ImageSize, Segment,
} from 'consts';
import {
  useCategories, useDynamicModules, usePost, useSegment, useToggledCategory,
} from 'contexts';
import {
  calcCategoryColor, getDisplayDate, getImageUrl, getPostCreator,
} from 'utils';
import { Button, PostLead, PostRedirect } from 'view/base';
import { Subscription, Survey } from 'view/components';
import Votes from 'view/components/Votes';

import RelatedPosts from './RelatedPosts';
import VotePosts from './VotePosts';

import './PostPage.scss';
import './PostPage.editor.scss';

const attribs = [{
  name: 'photo',
  label: 'Fotó',
}, {
  name: 'illustration',
  label: 'Illusztráció',
}];

const modulesMap = {
  POLL: VotePosts,
  SUBSCRIPTION: Subscription,
  SURVEY: Survey,
};

const injectImageWrappers = (content, gallery) => {
  if (!gallery?.media) {
    return content;
  }

  const injectedContent = (content || '').replace(/<img(?:.+?)>/ig, (img) => {
    const [, imageId] = img.match(/\/api\/image\/(\d+)/i) || [];
    const imageIdx = findIndex(gallery.media, ({ id }) => id === +imageId);
    if (imageIdx < 0) {
      return img;
    }

    const [, style = ''] = img.match(/style="(.+?)"/) || [];
    return `<div class="gallery-image" data-image-id="${imageId}" data-image-idx="${imageIdx}" style="${style}">${img.replace(/style="(?:.+?)"/i, '')}</div>`;
  });

  return injectedContent;
};

const PostPage = ({
  className, history, location, match, code,
}) => {
  const { activeSegment } = useSegment();
  const { pathname } = location;
  const { params: { post: slug } } = match;
  const { categories = [] } = useCategories();
  const {
    post, related = [], error, clearCache,
  } = usePost(slug);
  const [galleryItem, setGalleryItem] = useState(null);

  const { setSz2020 } = useToggledCategory();

  const postId = post && post.id;

  // clear item from cache when component is unmounted
  useEffect(() => () => {
    if (postId) {
      clearCache();
    }
  }, [postId, clearCache]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    title, lead, content, attributes, contentMode, approvedAt, updatedAt, createdAt, redirectUrl, segment,
    image, imageSecondary, imageStyles, categories: { 0: category } = [], tags = [],
    gallery,
  } = post || {};

  useEffect(() => {
    if (post && post.id) {
      if (post && post.tags && post.tags.length && tags && tags.length && tags.find((t) => t.name === 'technikai_sz_2020_also' || t.name === 'technikai_sz_2020_felso')) {
        setSz2020(true);
        return () => {
          setSz2020(false);
        };
      }
      setSz2020(false);
    }
    return () => null;
  }, [post]); // eslint-disable-line react-hooks/exhaustive-deps

  const categoryColor = useMemo(() => category && categories && calcCategoryColor(category, categories), [categories, category]);
  const externalProps = useMemo(() => ({
    postId, onRedirect: (url) => history.replace(url),
  }), [postId, history]);
  const { html: contentHtml, ref } = useDynamicModules(content, {
    main: true, externalProps, modulesMap, contentMode,
  });

  const onGalleryItemClick = useCallback((event) => {
    setGalleryItem(+event.currentTarget.dataset.imageIdx);
    document.querySelector('html').classList.add('no-scroll');
  }, []);

  const onCloseGalleryClick = useCallback((/* event */) => {
    setGalleryItem(null);
    document.querySelector('html').classList.remove('no-scroll');
  }, []);

  /*
  const printPage = () => {
    const printForm = document.createElement('form');
    printForm.target = 'Print';
    printForm.method = 'GET';
    printForm.action = `https://dev.csalad.hu/kalkulator/iframe/filter/${post?.id}`;
    document.body.appendChild(printForm);
    const print = window.open('', 'Print', 'status=0,title=0,height=600,width=800,scrollbars=1');
    if (print) {
      printForm.submit();
    } else {
      alert('Engedélyezze a felugróablakokat a funkcióhoz.'); // eslint-disable-line
    }
  };
  */

  const contentRef = useRef();
  useEffect(() => {
    if (!contentRef.current || !gallery?.media) {
      return undefined;
    }
    const imageList = [];
    const imageWrappers = contentRef.current.querySelectorAll('[data-image-id]');
    each(imageWrappers, (wrapper) => {
      wrapper.addEventListener('click', onGalleryItemClick);
      imageList.push(wrapper);
    });

    return () => {
      if (imageList) {
        each(imageList, (wrapper) => wrapper.removeEventListener('click', onGalleryItemClick));
      }
    };
  }, [gallery, onGalleryItemClick]);
  const { script } = attributes || {};
  useEffect(() => {
    if (!script) {
      return;
    }
    // eslint-disable-next-line no-eval
    eval(script);
  }, [script]);

  // check if post is found or not...
  if (post === null || error) {
    // ... and return 404 or redirec to root page
    return <Redirect to="/" />;
  }

  if (!post) {
    return <div className={clsx(className, 'post-page')} />;
  }

  if (post && ((segment !== Segment.$GENERIC && activeSegment !== segment) || redirectUrl)) {
    return <PostRedirect post={post} />;
  }

  const pageUrl = `${HOST_URL}${pathname}`;
  const displayDate = getDisplayDate(approvedAt || updatedAt || createdAt);
  const ogImageUrl = getImageUrl([image, imageSecondary], ImageSize.FACEBOOK, { imageStyles, cropId: ImageCrop.WIDE }, null);
  const twitterImageUrl = getImageUrl([image, imageSecondary], ImageSize.TWITTER, { imageStyles, cropId: ImageCrop.SQUARE }, null);
  const postImageUrl = getImageUrl([image, imageSecondary], ImageSize.NORMAL, { imageStyles, cropId: ImageCrop.WIDE }, null);
  const creator = post && getPostCreator(post);

  return (
    <div className={clsx(className, 'post-page')} ref={ref}>
      {post && (
        <div className="page-inner">
          {(tags && tags.length && tags.find((t) => t.name === 'technikai_sz_2020_also')) ? (
            <div style={{
              maxWidth: '350px', position: 'fixed', right: 0, bottom: 0, zIndex: 2,
            }}
            >
              <img style={{ maxWidth: '100%' }} src="/resources/images/SZ2020_also_csaladhu-desktop.png" alt="csalad.hu Szechenyi 2020 logo" title="csalad.hu Szechenyi 2020 logo" />
            </div>
          ) : null}
          {(tags && tags.length && tags.find((t) => t.name === 'technikai_sz_2020_felso')) ? (
            <div
              className="sz2020felso"
              style={{
                maxWidth: '350px', position: 'fixed', left: 0, zIndex: 2,
              }}
            >
              <img className="rrfDesktop" style={{ maxWidth: '100%' }} src="/resources/images/RRF_desktop.png" alt="RRF logo" title="RRF logo" />
              <img className="rrfMobil" style={{ maxWidth: '100%' }} src="/resources/images/RRF_mobil.png" alt="RRF logo" title="RRF logo" />
            </div>
          ) : null}

          {attributes?.css && <style type="text/css">{attributes?.css}</style>}
          <Helmet>
            <title>{title} - csalad.hu</title>
            <meta name="description" content={attributes?.description || lead} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="hu_HU" />
            <meta property="og:site_name" content="Csalad.hu" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={attributes?.description || lead} />
            <meta property="og:url" content={pageUrl} />
            {ogImageUrl && <meta property="og:image" content={`${HOST_URL}${ogImageUrl}`} />}
            {ogImageUrl && HOST_URL.startsWith('https://') && <meta property="og:image:secure_url" content={`${HOST_URL}${ogImageUrl}`} />}
            {!!(ogImageUrl && image.contentType) && <meta property="og:image:type" content={image.contentType} />}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={attributes?.description || lead} />
            {twitterImageUrl && <meta name="twitter:image" content={`${HOST_URL}${twitterImageUrl}`} />}
          </Helmet>
          {/* category && <CategoryBreadcrumb className="d-none" categories={categories} category={category} /> */}
          <Row>
            <Col className="area-article" xs={12} sm={9}>
              <article className={clsx('post', { 'no-image': !postImageUrl })}>
                {postImageUrl && (
                  <div className="post-image">
                    <div className="post-image-inner">
                      <div className="post-image-mask" />
                      <img src={postImageUrl} alt={title} title={title} />
                    </div>
                  </div>
                )}
                <header>
                  <h1 className="main-article-title">{title}</h1>
                  <h3 className="main-article-info">
                    {!!creator && (
                      <span className="created-by">
                        <span className="label">Szerző:</span>
                        <span className="value">{creator}</span>
                      </span>
                    )}
                    {!!displayDate && <span className="created-at">{displayDate}</span>}
                    {!!category && (
                      <NavLink to={getCategoryUrl({ slug: category.slug })} style={{ textDecoration: 'none' }}>
                        <span className="category" style={{ color: categoryColor }}>{category.name}</span>
                      </NavLink>
                    )}
                    {(!!attributes && !!attributes.relatedPostsOnlyFromFirstTag && !!tags && tags[0]) && (
                      <NavLink to={getTagUrl({ slug: tags[0].slug })} style={{ textDecoration: 'none' }}>
                        <span className="category" style={{ color: categoryColor }}>{tags[0].name}</span>
                      </NavLink>
                    )}
                  </h3>
                  { /* <div className="main-article-info" role="button" style={{ fontSize: '20px', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => printPage()}>Nyomtatás</div> */}
                  <PostLead className="main-article-lead" component="h2" lead={lead} />
                </header>
                <div className="post-content content">
                  <div
                    ref={contentRef}
                    className="ql-editor ck-content"
                    dangerouslySetInnerHTML={{ __html: injectImageWrappers(trimEnd(contentHtml, '<p>&nbsp;</p>'), gallery) } /* eslint-disable-line react/no-danger */}
                  />
                </div>
                <div className="clear" />
                <div className="post-meta">
                  <div className="separator" />
                  <div className="attribs">
                    {attribs.map(({ name, label }) => attributes?.[name] && (
                      <div key={name} className={`attrib-${name}`}>
                        <span className="label">{label}:</span>
                        <span className="value">{attributes?.[name]}</span>
                      </div>
                    ))}
                  </div>
                  {!!tags.length && (
                    <div className="tags">
                      {tags.filter((tag) => !tag.name.includes('technikai_')).map((tag) => (
                        <NavLink key={tag.id} to={getTagUrl({ slug: tag.slug })} className="item" style={tag.styles}>
                          {trimStart(tag.name, '#')}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              </article>
              <div>
                <Switch>
                  <Route path="/csaladban-elni/onkent-jottem-dij-2024" component={Votes} exact />
                </Switch>
              </div>
            </Col>
            <Col xs={3} className="d-block d-sm-block area-sidebar col-12 col-sm-3">
              <RelatedPosts posts={related} />
            </Col>
          </Row>
          {code && <div className="page-code" dangerouslySetInnerHTML={{ __html: code }} />} {/* eslint-disable-line react/no-danger */}
        </div>
      )}
      {gallery && !isNil(galleryItem) && (
        <div className="gallery">
          <div className="gallery-head">
            <h1 className="title">{gallery.title || title}</h1>
            {gallery.description && <h2 className="lead">{gallery.description}</h2>}
            <Button className="toggle-button material-icons" onClick={onCloseGalleryClick} title="Ablak bezárása">close</Button>
          </div>
          <Carousel selectedItem={galleryItem}>
            {gallery.media.map((media) => (
              <div className="gallery-item">
                <img key={media.id} src={getImageUrl(media.id, ImageSize.NORMAL)} alt={media.title} title={media.title} />
                {media.title && <div className="gallery-item-title">{media.title}</div>}
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

PostPage.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  className: PropTypes.string,
  code: PropTypes.string,
};

PostPage.defaultProps = {
  className: '',
  code: undefined,
};

export default PostPage;
