import { set, size } from 'lodash';
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import { CategoryStatus, ChildrenPropType } from 'consts';
import { createRootCategoryMenuAdapter } from 'utils';

import { useCategories } from './Services';

const initState = {
  opened: {},
  rootCategorySlug: undefined,
  rootUrl: undefined,
  sz2020: false,
};
const Context = createContext({
  ...initState,
  toggle: () => {},
  setSz2020: () => {},
  setRootCategory: () => {},
});

const ToggledCategoryProvider = ({ children }) => {
  const [state, setState] = useState(initState);
  const { categories } = useCategories();
  const { opened } = state;
  useEffect(() => {
    if (size(opened)) {
      return;
    }

    if (!categories) {
      setState(({ rootCategorySlug, rootUrl }) => ({ ...initState, rootCategorySlug, rootUrl }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      opened: categories.reduce((result, { id, status }) => set(result, id, status === CategoryStatus.OPENED), {}),
    }));
  }, [categories, opened]);

  const toggle = useCallback((id) => {
    setState((prevState) => ({
      ...prevState,
      opened: { ...prevState.opened, [id]: !prevState.opened[id] },
    }));
  }, []);

  const setSz2020 = useCallback((type) => {
    setState((prevState) => ({
      ...prevState,
      sz2020: type,
    }));
  }, []);

  const setRootCategory = useCallback((rootCategorySlug, rootUrl) => {
    setState((prevState) => ({ ...prevState, rootCategorySlug, rootUrl }));
  }, []);

  return (
    <Context.Provider value={{
      ...state, categories, toggle, setRootCategory, setSz2020,
    }}
    >{children}
    </Context.Provider>
  );
};

ToggledCategoryProvider.propTypes = {
  children: ChildrenPropType.isRequired,
};

export const useToggledCategory = () => {
  const {
    categories, opened, rootCategorySlug, ...values
  } = useContext(Context);

  const adapted = useMemo(
    () => {
      const adapter = rootCategorySlug && createRootCategoryMenuAdapter(rootCategorySlug);
      return adapter ? adapter(categories, opened) : { categories, opened, allowToggle: true };
    },
    [rootCategorySlug, categories, opened],
  );

  return { ...values, ...adapted };
};

export default ToggledCategoryProvider;
