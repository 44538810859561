import axios from 'axios';
import { useCallback, useEffect, useRef } from 'react';

const useLoadMore = ({
  service, params, config = {}, startRequest, endRequest, transformFn, checkerFn,
}) => {
  const fnsRef = useRef({ transformFn, checkerFn });
  useEffect(() => {
    fnsRef.current = { transformFn, checkerFn };
  }, [transformFn, checkerFn]);

  return useCallback(async () => {
    const cancelTokenSource = axios.CancelToken.source();

    let blockRequest = false;
    startRequest({
      cancelTokenSource,
      onBeforeStart: (state) => {
        blockRequest = state?.loading || fnsRef.current.checkerFn?.(state) === false;
        return blockRequest === true;
      },
    });
    if (blockRequest) {
      return;
    }

    try {
      const response = await service(params, { api: axios, ...config });

      endRequest({ response, transformFn: fnsRef.current.transformFn });
    } catch (error) {
      endRequest({ response: error.response, error, transformFn: fnsRef.current.transformFn });
    }
  }, [config, endRequest, params, service, startRequest]);
};

export default useLoadMore;
