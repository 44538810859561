import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const HamburgerIcon = ({ className }) => (
  <svg className={clsx('hamburger-icon', className)} preserveAspectRatio="none" viewBox="0 0 28 20" width="28" height="20">
    <rect y="3" width="28" height="2" />
    <rect y="10" width="28" height="2" />
    <rect y="17" width="28" height="2" />
  </svg>
);

HamburgerIcon.propTypes = {
  className: PropTypes.string,
};

HamburgerIcon.defaultProps = {
  className: '',
};
export default HamburgerIcon;
