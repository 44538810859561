export { default as ThematicPages } from './thematic-pages';

export const POST_MAX_PRIORITY = 54;

export const AUTH_COOKIE_NAME = 'CS_HU_AUTH';
export const SEGMENT_COOKIE_NAME = 'CS_HU_SEGMENT';

export const PASSWORD_CHANGE_URL = '/jelszo-modositas/:token';

const DEFAULT_HOST_URL = 'https://csalad.hu';
export const getCommonConfig = (env) => ({
  FACEBOOK_APP_ID: env.REACT_APP_FACEBOOK_APP_ID || '-1',
  FACEBOOK_DOMAIN_VERIFICATION: env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION || null,
  HOST_URL: env.REACT_APP_CSALAD_HOST_URL || env.CSALAD_HOST_URL || DEFAULT_HOST_URL,
});

export const Validator = {
  email: (email) => (new RegExp([
    '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|',
    '(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|',
    '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  ].join(''))).test(email),
};

export const ImageCrop = {
  WIDE: 'wide',
  NARROW: 'narrow',
  SQUARE: 'square',
};

export const ImageCropRatio = {
  [ImageCrop.WIDE]: 2 / 1,
  [ImageCrop.NARROW]: 3 / 2,
  [ImageCrop.SQUARE]: 1 / 1,
};

export * from './enums';
export * from './utils';
