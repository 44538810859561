import { useMemo } from 'react';

import { PaginationConfig, Segment } from 'consts';

import { useCachedService, useService, useServices } from './ServiceProvider';
import useLoadMore from './useLoadMore';
import useSegment from './useSegment';

export const useCategories = () => {
  const { getCategories } = useServices();
  const { state, state: { error, data: { categories } = {} }, ...result } = useCachedService(getCategories);

  return {
    ...state,
    ...result,
    categories: error ? null : categories,
  };
};

export const useBanners = () => {
  const { getBanners } = useServices();
  const { state, state: { error, data: { banners } = {} }, ...result } = useCachedService(getBanners);

  return {
    ...state,
    ...result,
    banners: error ? null : banners,
  };
};

export const useCategory = (categoryId) => {
  const { getCategory } = useServices();
  const { activeSegment } = useSegment();
  const { state, state: { error, data: { category } = {} }, ...result } = useCachedService(getCategory, { categoryId, segment: activeSegment });

  return {
    ...state,
    ...result,
    category: error ? null : category,
  };
};

export const usePost = (slug, no404 = false) => {
  const { getPostBySlug } = useServices();

  let params = { slug };
  if (no404) {
    params = { slug, no404 };
  }
  const { state, state: { error, data: { post, related } = {} }, ...result } = useCachedService(getPostBySlug, params);

  return {
    ...state,
    ...result,
    post: error ? null : post,
    related,
  };
};

export const useRedirects = () => {
  const { getRedirects } = useServices();

  const { state, state: { error, data: { redirects } = {} }, ...result } = useCachedService(getRedirects, null);
  return {
    ...state,
    ...result,
    redirects: error ? null : redirects,
  };
};

export const usePostSearch = (query, page) => {
  const { getPostsBySearch } = useServices();
  const { state, state: { error, data: { posts, pages = 1 } = {} }, ...result } = useCachedService(getPostsBySearch, { query, page });

  return {
    ...state,
    ...result,
    posts: error ? null : posts,
    pages,
  };
};

export const usePosts = (config, params = {}) => {
  const { getPosts } = useServices();
  const { activeSegment } = useSegment();
  const {
    state,
    state: { error, data: { posts, categoryPosts } = {} },
    startRequest,
    endRequest,
    ...result
  } = useCachedService(getPosts, {
    segment: activeSegment,
    limit: PaginationConfig.MainPostFirst * (activeSegment === Segment.FAMILY ? 1 : 10),
    ...params,
  }, config);

  return {
    ...state,
    ...result,
    posts: error ? null : posts,
    categoryPosts: error ? null : categoryPosts,
  };
};

const useLoadMorePosts = ({
  slug, service, limitFirst, limitNext,
}) => {
  const {
    state,
    state: { error, data: { posts = [] } = {} },
    startRequest,
    endRequest,
    ...result
  } =
    useCachedService(service, {
      slug,
      limit: limitFirst,
    });

  const offset = posts?.length || 0;
  const params = useMemo(() => ({
    slug,
    offset,
    limit: limitNext,
  }), [slug, offset, limitNext]);

  const loadMore = useLoadMore({
    service,
    params,
    startRequest,
    endRequest,
    checkerFn: ({ response }) => response?.data?.count > response?.data?.posts?.length,
    transformFn: ({ response, error: err, prevResponse }) => {
      if (err) {
        return { response: prevResponse, error: err };
      }
      const { data, data: { posts: newPosts, count } } = response;
      const { data: { posts: prevPosts } } = prevResponse;

      return {
        response: {
          ...response,
          data: {
            ...data,
            posts: [...prevPosts, ...newPosts],
            count,
          },
        },
      };
    },
  });

  return {
    ...state,
    ...result,
    posts: error ? null : posts,
    loadMore,
  };
};

export const usePostsByCategorySlug = (slug) => {
  const { getPostsByCategorySlug } = useServices();
  return useLoadMorePosts({
    slug,
    service: getPostsByCategorySlug,
    limitFirst: PaginationConfig.CategoryPostFirst,
    limitNext: PaginationConfig.CategoryPostNext,
  });
};

export const usePostsByTagSlug = (slug) => {
  const { getPostsByTagSlug } = useServices();
  return useLoadMorePosts({
    slug,
    service: getPostsByTagSlug,
    limitFirst: PaginationConfig.CategoryPostFirst,
    limitNext: PaginationConfig.CategoryPostNext,
  });
};

export const useTag = (slug) => {
  const { getTagBySlug } = useServices();
  const { activeSegment } = useSegment();
  const { state, state: { error, data: { tag } = {} }, ...result } = useCachedService(getTagBySlug, { slug, segment: activeSegment });

  return {
    ...state,
    ...result,
    tag: error ? null : tag,
  };
};

export const useTagCloud = () => {
  const { getTagCloud } = useServices();
  const { activeSegment } = useSegment();
  const { state, state: { error, data: { tags } = {} }, ...result } = useCachedService(getTagCloud, { segment: activeSegment });

  return {
    ...state,
    ...result,
    tags: error ? null : tags,
  };
};

export const useSubscription = (options) => {
  const { postSubscription } = useServices();
  const {
    state, state: { error, data }, refetch, ...result
  } = useService(postSubscription, options);

  return {
    ...state,
    ...result,
    subscription: error ? null : data?.result,
    subscribeByEmail: refetch,
  };
};

export const usePostVote = (options) => {
  const { postVote } = useServices();
  const {
    state, state: { error, data }, refetch, ...result
  } = useService(postVote, options);

  return {
    ...state,
    ...result,
    result: error ? null : data?.result,
    postVote: refetch,
  };
};

export const usePostSurvey = (options) => {
  const { postSurvey } = useServices();
  const {
    state, state: { error, data }, refetch, ...result
  } = useService(postSurvey, options);

  return {
    ...state,
    ...result,
    result: error ? null : data?.result,
    postSurvey: refetch,
  };
};

export const useMedia = (slug) => {
  const { getMediaBySlug } = useServices();
  const { state, state: { error, data: { media } = {} }, ...result } = useCachedService(getMediaBySlug, { slug });

  return {
    ...state,
    ...result,
    media: error ? null : media,
  };
};

export const usePostsByPollId = (pollId) => {
  const { getPostsByPollId } = useServices();
  const { state, state: { error, data: { posts } = {} }, ...result } = useCachedService(getPostsByPollId, { pollId });

  return {
    ...state,
    ...result,
    posts: error ? null : posts,
  };
};
