import { find } from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { RealSegment, Segment, SegmentUrl } from 'consts';

const useSegment = (defaultSegment = Segment.SUBSIDY) => {
  const { pathname } = useLocation();
  const activeSegment = useMemo(
    () => find(RealSegment, (segment) => pathname.startsWith(SegmentUrl[segment])) || defaultSegment,
    [defaultSegment, pathname],
  );

  return { activeSegment };
};

export default useSegment;
